import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameVersion } from "../../resources/version.resource";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";
import { handleGetDeveloperGame, handleUpdateDeveloperGame } from "../../handlers/developers";
import { GameControllerHook } from "./use-game-controller";
import useLoadingBasics from "../use-loading-basics";

interface GameVersionsProps {
	info: any;
	assets: any;
	controller: GameControllerHook;
}

export interface GameVersionsHook {
	v: GameVersion;
	versions: GameVersion[];
	getVersions: () => Promise<void>;
	updateVersion: (nVersion: GameVersion, vIndex: any) => Promise<void>;
	gameId: string;
}

export const defaultGameVersionsHook: GameVersionsHook = {
	v: {} as GameVersion,
	versions: [],
	getVersions: async () => {},
	updateVersion: async () => {},
	gameId: "",
};

const useGameVersions: (props: GameVersionsProps) => GameVersionsHook = ({ info, assets, controller }) => {
	//context and external data
	const { auth } = useContext(GlobalContext);
	const user = useMemo(() => {
		return auth.user;
	}, [auth]);
	const navigate = useNavigate();
	//internal state and data
	const { gameId, version } = useParams();
	const intVersion = useMemo(() => parseInt(version ?? "0"), [version]);
	const [versions, setVersions] = useState<GameVersion[]>([]);
	const status = useLoadingBasics();

	//preventative measures to make sure users are signed in and are trying to access a valid game
	useEffect(() => {
		if (!auth.loading) {
			if (!gameId || !auth.signedIn) {
				navigate("/");
			}
			if (!version) {
				navigate(`/${gameId}/1`);
			}
		}
	}, [auth, version, gameId]);

	//hook to get the actual data and versions of the game
	useEffect(() => {
		if (auth.loading) return;
		if (!auth.signedIn) navigate("/");
		getVersions();
	}, [user]);

	const setDataFromVersion = useCallback(
		async (ver) => {
			if (ver.length === 0) return;
			let versionIndex = intVersion - 1;
			if (versionIndex === -1) {
				navigate(`/${gameId}/1`);
			}
			if (versionIndex >= ver.length) {
				navigate(`/${gameId}/${ver.length}`);
				versionIndex = ver.length - 1;
			}
			//set the states to the values of the current version
			info.update(ver[versionIndex]);
			assets.update(ver[versionIndex]);
			//update the controller url
			await controller.getUrl(ver[versionIndex]?.controller);
		},
		[info, assets, navigate, intVersion]
	);

	const v = useMemo(() => {
		return versions?.[intVersion - 1];
	}, [intVersion, versions]);

	const getVersions = async () => {
		if (gameId) {
			status.loading();
			const data = await handleGetDeveloperGame(gameId);
			if (data && data.length > 0) {
				await setDataFromVersion(data);
				setVersions(data);
				status.success();
			} else {
				navigate("/");
				status.error("no data was found");
			}
		}
	};

	const updateVersion = async (nVersion: GameVersion, vIndex) => {
		const updated = await handleUpdateDeveloperGame(gameId, vIndex, nVersion);
		if (updated) {
			const updatedVersions = versions.map((v) => {
				if (v.version === vIndex) {
					return {
						...v,
						...nVersion,
					};
				}
				return v;
			});
			setVersions(updatedVersions);
		}
	};

	return {
		gameId: gameId || "",
		intVersion,
		v,
		versions,
		getVersions,
		updateVersion,
	};
};

export default useGameVersions;
