import { useEffect, useState } from "react";
import CustomGame from "./custom";
import UnityGame from "./unity";
import Unity5Game from "./unity5";

let timeout;
const wakeTime = 2000;

export default function Game({ socketRef, roomID, game, readyToExit, afterReadyToExit }) {
	const [devicePixelRatio, setDevicePixelRatio] = useState(1);
	useWakeLock();

	useEffect(() => {
		if (typeof window !== "undefined") {
			setDevicePixelRatio(window.devicePixelRatio);
		}
	}, []);

	useEffect(() => {
		const styleElement = document.createElement("style");
		const cssRule = document.createTextNode("* { cursor: none !important; }");

		styleElement.appendChild(cssRule);

		function showMouseCursor() {
			clearTimeout(timeout);
			if (document.head.contains(styleElement)) {
				document.head.removeChild(styleElement);
			}
			timeout = setTimeout(() => {
				document.head.appendChild(styleElement);
			}, wakeTime);
		}

		timeout = setTimeout(() => {
			document.head.appendChild(styleElement);
		}, wakeTime);

		document.addEventListener("mousemove", showMouseCursor);
		document.addEventListener("mousedown", showMouseCursor);

		return () => {
			clearTimeout(timeout);
			if (document.head.contains(styleElement)) {
				document.head.removeChild(styleElement);
			}
			document.removeEventListener("mousemove", showMouseCursor);
			document.removeEventListener("mousedown", showMouseCursor);
		};
	}, []);

	// useEffect(() => {
	//   if (typeof document === "undefined") return;
	//   const styleElement = document.createElement('style');
	//   const cssRule = document.createTextNode('* { cursor: none !important; }');
	//   styleElement.appendChild(cssRule);
	//   function hideMouseCursor() {
	//     // set * { cursor: none !important; } in css
	//     document.head.appendChild(styleElement);
	//   }
	//   function showMouseCursor() {
	//     clearTimeout(timeout);
	//     // set * { cursor: auto; } in css
	//     document.head.removeChild(styleElement);
	//     timeout = setTimeout(hideMouseCursor, wakeTime);
	//   }
	//
	//   timeout = setTimeout(hideMouseCursor, wakeTime);
	//
	//   document.addEventListener('mousemove', showMouseCursor);
	//   document.addEventListener('mousedown', showMouseCursor);
	//
	//   return () => {
	//     document.head.removeChild(styleElement);
	//     clearTimeout(timeout);
	//     document.removeEventListener('mousemove', showMouseCursor);
	//     document.removeEventListener('mousedown', showMouseCursor);
	//   };
	// }, []);

	const sendMessageToPlayer = (id, message) => {
		socketRef.current.emit("message to player", { id, message });
	};

	const sendMessageToAllPlayers = (message) => {
		socketRef.current.emit("message to all players", {
			roomId: roomID.current,
			message,
		});
	};

	const exit = () => {
		socketRef.current.emit("exit", roomID.current);
		socketRef.current.emit("create room", game);
	};

	if (game.type === "unity") {
		return (
			<UnityGame
				devicePixelRatio={devicePixelRatio}
				game={game}
				exit={exit}
				socketRef={socketRef}
				roomID={roomID}
				afterReadyToExit={afterReadyToExit}
				sendMessageToPlayer={sendMessageToPlayer}
				sendMessageToAllPlayers={sendMessageToAllPlayers}
				readyToExit={readyToExit}
			/>
		);
	} else if (game.type === "unity5") {
		return (
			<Unity5Game
				devicePixelRatio={devicePixelRatio}
				game={game}
				exit={exit}
				socketRef={socketRef}
				roomID={roomID}
				afterReadyToExit={afterReadyToExit}
				sendMessageToPlayer={sendMessageToPlayer}
				sendMessageToAllPlayers={sendMessageToAllPlayers}
				readyToExit={readyToExit}
			/>
		);
	}
	return (
		<CustomGame
			exit={exit}
			socketRef={socketRef}
			roomID={roomID}
			game={game}
			readyToExit={readyToExit}
			afterReadyToExit={afterReadyToExit}
		/>
	);
}

function useWakeLock() {
	useEffect(() => {
		let wakeLock = null;

		async function requestWakeLock() {
			try {
				wakeLock = await navigator.wakeLock.request("screen");
				// console.log("Wake Lock is active.");

				wakeLock.addEventListener("release", () => {
					// console.log("Wake Lock was released");
				});
			} catch (err) {
				console.error(`Wake Lock Error: ${err.name}, ${err.message}`);
			}
		}

		requestWakeLock();

		return () => {
			wakeLock?.release().then(() => {
				// console.log("Wake Lock was released upon component unmount");
			});
		};
	}, []);
}
