import { useContext, useEffect, useState } from "react";
import { PageBox, RowBox, ScrollableBox } from "../../components/boxes";
import { Box, Button, styled, useMediaQuery } from "@mui/material";
import { Text } from "../../components/text";
import { colors } from "../../styles/styles";
import { handleAddDeveloperGameDefault, handleGetDeveloperGames } from "../../handlers/developers";
import Footer from "../../components/footer";
import { formatDateTimestamptzToMMDDYYYY } from "../../lib/global-functions";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";
import DeveloperNavBar from "../../components/layout/developer-nav-bar";

const AddButton = styled(Button)({
	padding: "5px 15px",
	borderRadius: 10,
	background: colors.redMain,
	textTransform: "none",
	"&:hover": {
		background: colors.redMain,
	},
	"&:active": {
		background: colors.redSecondary,
	},
});

const GamePreviewButton = styled(Button)({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	width: "calc(100vw - 50px)",
	padding: "10px 50px",
	border: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	borderRadius: 10,
	backgroundColor: colors.whiteMain,
	textTransform: "none",
	"&:hover": {
		background: colors.whiteMain,
	},
	"&:active": {
		background: colors.grayMain,
	},
});

function DeveloperPortal() {
	const isMobile = useMediaQuery("(max-width: 600px)");
	const { auth } = useContext(GlobalContext);
	const [games, setGames] = useState([]);
	const navigate = useNavigate();
	const [triggerGetGames, setTriggerGetGames] = useState(false);

	useEffect(() => {
		if (!auth.signedIn || !auth.user?.id) return;
		setTriggerGetGames(true);
	}, [auth.user, auth]);

	useEffect(() => {
		if (!triggerGetGames) return;
		handleGetDeveloperGames().then((data) => {
			if (data && data.length > 0) {
				const seenIds = [];
				for (let i = 0; i < data.length; i++) {
					if (seenIds.includes(data[i].game_id)) {
						continue;
					}
					seenIds.push(data[i].game_id);
					setGames((prev) => {
						return [...prev, data[i]];
					});
				}
			}
		});
	}, [triggerGetGames]);

	const onAddGame = async () => {
		const data = await handleAddDeveloperGameDefault();
		if (data) {
			navigate(`/${data}/1`);
		} else {
			console.log("error adding game");
		}
	};

	if (isMobile) {
		return (
			<PageBox>
				<DeveloperNavBar />
				<Box style={{ height: 44 + 25 }} />
				<RowBox width={"calc(100vw - 30px)"} justify={"space-between"}>
					<Text size={24} weight={700}>
						My Games
					</Text>
				</RowBox>
				<Box style={{ height: 15 }} />
				<ScrollableBox>
					{games.map((game, index) => {
						return (
							<Link key={index} to={`/${game.game_id}/${game.version}`} style={{ textDecoration: "none" }}>
								<GamePreviewButton disableRipple={true} style={{ padding: "10px 15px", width: "calc(100vw - 30px)" }}>
									<RowBox gap={10} style={{ flex: 1 }} justify={"flex-start"}>
										{game.mini_icon_url ? (
											<img src={game.mini_icon_url} style={{ width: 20, height: 20 }} />
										) : (
											<Box style={{ width: 20, height: 20, background: colors.grayMain, borderRadius: 5 }} />
										)}
										{game.title ? (
											<Text color={colors.redMain} weight={700}>
												{game.title}
											</Text>
										) : (
											<Text>no game title</Text>
										)}
									</RowBox>
									<Text size={14} weight={600} style={{ flex: 1 }} align={"right"}>
										Version {game.version}
									</Text>
								</GamePreviewButton>
							</Link>
						);
					})}
				</ScrollableBox>
				<Box style={{ flex: 1, minHeight: 15 }} />
				<Footer />
			</PageBox>
		);
	}

	return (
		<PageBox>
			<DeveloperNavBar />
			<Box style={{ height: 44 + 25 }} />
			<RowBox width={"calc(100vw - 50px)"} justify={"space-between"}>
				<Text size={32} weight={700}>
					My Games
				</Text>
				<AddButton disableRipple={true} onClick={onAddGame}>
					<RowBox gap={10}>
						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
							<path
								d="M6 0C5.33726 0 4.8 0.537259 4.8 1.2V4.8H1.2C0.537258 4.8 0 5.33726 0 6C0 6.66274 0.537259 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.33726 12 6 12C6.66274 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.66274 12 6C12 5.33726 11.4627 4.8 10.8 4.8H7.2V1.2C7.2 0.537258 6.66274 0 6 0Z"
								fill="white"
							/>
						</svg>
						<Text size={14} weight={700} color={colors.whiteMain}>
							Add game
						</Text>
					</RowBox>
				</AddButton>
			</RowBox>
			<Box style={{ height: 25 }} />
			<ScrollableBox>
				{games.map((game, index) => {
					return (
						<GamePreviewButton
							key={index}
							onClick={() => {
								navigate(`/${game.game_id}/${game.version}`);
							}}
							disableRipple={true}
						>
							<RowBox gap={10} style={{ flex: 1 }} justify={"flex-start"}>
								{game.mini_icon_url ? (
									<img src={game.mini_icon_url} style={{ width: 20, height: 20 }} />
								) : (
									<Box style={{ width: 20, height: 20, background: colors.grayMain, borderRadius: 5 }} />
								)}
								{game.title ? (
									<Text color={colors.redMain} weight={700}>
										{game.title}
									</Text>
								) : (
									<Text>no game title</Text>
								)}
							</RowBox>
							<Text size={12} style={{ flex: 1 }} align={"center"}>
								{formatDateTimestamptzToMMDDYYYY(game.last_updated)}
							</Text>
							<Text size={14} weight={600} style={{ flex: 1 }} align={"right"}>
								Version {game.version}
							</Text>
						</GamePreviewButton>
					);
				})}
			</ScrollableBox>
			<Box style={{ flex: 1, minHeight: 25 }} />
			<Footer />
		</PageBox>
	);
}

export default DeveloperPortal;
