/**
 *  A function to open up a stripe account link for the current user once finished if successfully completed it will append the
 *  query ?stripe-account-connected=true or false to the url so when the user gets back make sure to check for that
 * @param {string} token A jwt (javascripot web token) for the current user's supabase account
 */
export const openStripeLink = async (token) => {
	const result = await fetch("/stripe/get-account-link", {
		method: "POST",
		//send the current url the user is on to the server so we can get back to the same url after completing the stripe account setup
		body: JSON.stringify({ current_url: window.location.href }),
		headers: {
			//pass in the token for the supabase user
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json"
		}
	});
	//parse the result back into a json object
	const data = await result.json();
	if (data?.url) {
		//if we successfully obtained a link open the link
		window.location.href = data.url;
		return true;
	}
	return false;
};

export const getConnectedAccountInfo = async (token) => {
	const result = await fetch("/stripe/get-payout-options", {
		method: "GET",
		headers: {
			//pass in the token for the supabase user
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json"
		}
	});
	const data = await result.json();
	console.log(data);
};

export const getStripeAccountInfo = async (token) => {
	try {
		const result = await fetch("/stripe/get-account-status", {
			method: "GET",
			headers: {
				//pass in the token for the supabase user
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json"
			}
		});
		const data = await result.json();
		return data;
	} catch (error) {
		console.error(error);
		return { error: "An error occured while trying to get the stripe account info." };
	}
};

export const getStripeLoginLink = async (token) => {
	const result = await fetch("/stripe/get-login-link", {
		method: "GET",
		headers: {
			//pass in the token for the supabase user
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json"
		}
	});
	const data = await result.json();
	if (data?.url) {
		//if we successfully obtained a link open the link
		window.location.href = data.url;
		return true;
	}
	return false;
};
