import styled from "styled-components";
import { Text } from "../text";
import { colors } from "../../styles/styles";
import { RowBox } from "../boxes";
import { FolderIcon } from "../icons";
import { Box } from "@mui/material";
import { getFileNameFromURL } from "../../resources/s3.resource";

export const EmptyFileHolder = styled(Box)({
	padding: "5px 15px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: 37,
	flex: 1,
	borderRadius: 10,
	border: "1px dashed var(--black-50, rgba(0, 0, 0, 0.50))",
});

const ContainsFileHolder = styled(EmptyFileHolder)({
	backgroundColor: colors.grayMain,
	border: "none",
});

const BuildFileHolder = ({ url, file, fileExtension }) => {
	if (!url && !file) {
		return (
			<EmptyFileHolder>
				<Text size={12} color={colors.black50}>
					*{fileExtension}
				</Text>
			</EmptyFileHolder>
		);
	}
	if (file) {
		return (
			<ContainsFileHolder>
				<RowBox gap={5}>
					<FolderIcon large={true} />
					<Text size={14} weight={700} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
						{file.name}
					</Text>
				</RowBox>
			</ContainsFileHolder>
		);
	}
	return (
		<ContainsFileHolder>
			<RowBox gap={5}>
				<FolderIcon large={true} />
				<Text size={14} weight={700} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
					{getFileNameFromURL(url)}
				</Text>
			</RowBox>
		</ContainsFileHolder>
	);
};

export default BuildFileHolder;
