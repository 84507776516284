import { ColumnBox, RowBox, HorizontalLine } from "./boxes";
import { LogoVertical } from "./icons";
import { Text } from "./text";
import { Box } from "@mui/material";
import { colors } from "../styles/styles";

export default function Footer({ includeSocials }) {
	return (
		<ColumnBox>
			<HorizontalLine />
			<Box style={{ height: 25 }} />
			<ColumnBox gap={15}>
				<LogoVertical />
				<RowBox gap={10}>
					<Text size={14}>© 2024 Madder. All rights reserved.</Text>
					<Text
						size={14}
						weight={700}
						color={colors.redMain}
						onClick={() => {
							window.open("https://www.maddergames.com/privacy-policy");
						}}
						style={{ cursor: "pointer" }}
					>
						Privacy Policy
					</Text>
				</RowBox>
			</ColumnBox>
			<Box style={{ height: 25 }} />
		</ColumnBox>
	);
}
