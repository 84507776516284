import axios from "axios";
import { useCallback } from "react";

//shouldn't be hardcoded but should have a env variable
const baseURL = process.env.REACT_APP_BASE_URL ?? "https://maddergames.com";

export const defaultFetcher = async (url, data, cb, cbErr) => {
	return null;
};

/**
 * useFetcher is a hook that is used to get the fetcher function that is used to make API calls to the server
 *
 * @param token an optional parameter where if you have an auth token you want to constantly be used with each call pass it through
 * @returns
 * @component
 */
const useFetcher = ({ token }) => {
	/**
	 * A function that is used to make API calls to the server it helps to abstract the axios call
	 * @param {*} url - url that the fetcher will try and fetch from, if it doesn't contain https or http it will default to the baseURL and append the url to the end ex. baseURL + url (http://example.com + /api/v1/users)
	 * @param {*} [data] - an optional parameter that contains the method, body, params, headers, and config
	 * @param {*} [cb] - an optional callback function that is called when the request is successful
	 * @param {*} [cbErr] - an optional callback function that is called when the request fails, requires the cb function to be passed
	 * @returns the call data if there is no callback function
	 * @memberOf useFetcher
	 */
	const fetcher = useCallback(
		async (url, data, cb, cbErr) => {
			const options = {
				url: url.includes("https:") || url.includes("http:") ? `${url}` : `${baseURL}${url ?? ""}`,
				method: data?.method ?? "GET",
				data: data?.body ?? {},
				params: data?.params ?? {},
				headers: {
					// these can be overwritten when using the data.headers object since that is placed last
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
					...(token ? { Authorization: `Bearer ${token}` } : {}),
					...(data?.headers ?? {})
				},
				...(data?.config ?? {})
			};
			try {
				//if a callback was placed in the function call then use the callback otherwise return the response
				if (cb) {
					const blankCBErr = () => {};
					axios(options)
						.then(cb)
						.catch(cbErr ?? blankCBErr);
				} else {
					const response = await axios(options);
					return response;
				}
			} catch (err) {
				return err?.response?.data ?? null;
			}
		},
		[token]
	);

	return fetcher;
};

export default useFetcher;
