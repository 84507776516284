import { supabase } from "../lib/supabase";

export async function handleUpdateUserStats(gamername, gameId, stats) {
	const response = await fetch("/update-stats", {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({ gamername, gameId, stats })
	});
	return await response.json();
}

export async function handleGetUserToGames() {
	try {
		const { data, error } = await supabase.rpc("get_user_games", {});
		if (error) {
			throw error;
		}
		return data;
	} catch (error) {
		console.log({ error });
		throw error;
	}
}
