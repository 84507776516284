import { createContext, useMemo, useState } from "react";
import useGamePolicies, { defaultGamePoliciesHook } from "../hooks/game-management/use-game-policies";
import useGameInfoHook from "../hooks/game-management/use-game-info";
import useGameAssetsHook from "../hooks/game-management/use-game-assets";
import useGameVersions from "../hooks/game-management/use-game-version";
import useGameController from "../hooks/game-management/use-game-controller";
import useSaveSubmit from "../hooks/game-management/use-save-submit";
import useGameMedia, { defaultGameMediaHook } from "../hooks/game-management/use-game-media";

//default values for the global context that way if somehow the globalcontext isn't intializing or is taking a while we don't have undefined we have functions that potentially do nothing at least don't crash, more associated with typescript needs
const defaultGameManagement = { polices: defaultGamePoliciesHook, media: defaultGameMediaHook };

export const GameManagementContext = createContext(defaultGameManagement);

export const GameManagementProvider = ({ children }) => {
	const [waitingForApproval, setWaitingForApproval] = useState(false);
	const policies = useGamePolicies();
	const info = useGameInfoHook();
	const assets = useGameAssetsHook();
	const controller = useGameController();
	const gameVersions = useGameVersions({ assets, info, controller });
	const media = useGameMedia({ gameId: gameVersions.gameId, version: gameVersions.intVersion });
	const submission = useSaveSubmit({ info, assets, policies, gameVersions, media, setWaitingForApproval });
	//get the auth token and the fetcher function

	//make sure all the values are memoized so that the context provider does not re-render unnecessarily
	const value = useMemo(() => {
		//TODO - remove the waitingForApproval to a hook with the other data that needs to be stored and calculated
		return { policies, waitingForApproval, setWaitingForApproval, info, assets, gameVersions, controller, submission, media };
	}, [policies, waitingForApproval, setWaitingForApproval, info, assets, gameVersions, controller, submission, media]);

	//provide all the dat
	return <GameManagementContext.Provider value={value}>{children}</GameManagementContext.Provider>;
};
