import { Box, styled } from "@mui/material";
import { colors } from "../../styles/styles";
export const InfoBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	width: "calc(100% - 50px)",
	padding: "15px 25px",
	gap: 25,
	borderRadius: 10,
	border: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	backgroundColor: colors.whiteMain
});
