import { createContext, useMemo } from "react";
import useAuth, { defaultAuth } from "../hooks/Auth/use-auth";
import useFetcher, { defaultFetcher } from "../hooks/useFetcher";
import { defaultUserData } from "../hooks/global-context/use-user-data";
import useStripeData, { defaultStripeData } from "../hooks/global-context/use-stripe-data";
import useLoadingProgress from "../hooks/global-context/use-loading-progress";

//default values for the global context that way if somehow the globalcontext isn't intializing or is taking a while we don't have undefined we have functions that potentially do nothing at least don't crash, more associated with typescript needs
const defaultGlobalData = { supaFetch: defaultFetcher, auth: defaultAuth, ...defaultUserData, stripe: defaultStripeData };

export const GlobalContext = createContext(defaultGlobalData);

//any hook created for the global context should be added in here
//the hook can follow the formatting of
// - having a isLoading state (if appropriate)
// - having a loaded state (if appropriate)
// - having a default hook object that returns a default for what the hook what already be. Put that in the defaultGlobalData object
// - the results of the hook should be stored in an object named appropriately and passed to the memoized value object, being a dependency of the useMemo function

export const GlobalContextProvider = ({ children }) => {
	//get the auth token and the fetcher function
	const auth = useAuth();
	const supaFetch = useFetcher({ token: auth?.supaToken });
	// const user = useUserData();
	const stripeData = useStripeData({ token: auth?.supaToken });

	//gets the status of where the global context is at. needs more refinement to be more accurate
	const status = useLoadingProgress({ auth, stripeData });

	//make sure all the values are memoized so that the context provider does not re-render unnecessarily
	const value = useMemo(() => {
		return { auth, supaFetch, stripe: stripeData, status: status };
	}, [auth, supaFetch, stripeData, status]);

	//provide all the dat
	return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
