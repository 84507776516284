import { supabase } from "../lib/supabase";

export async function handleGetMadderUser(id) {
	const { data, error } = await supabase.rpc("get_user_from_id", { _id: id });
	if (error) {
		console.log({ error });
		throw error;
	}
	return data;
}

export async function handleSetGamername(gamername) {
	const { error } = await supabase.rpc("set_gamername", { _gamername: gamername });
	if (error) {
		console.log({ error });
		throw error;
	}
}

export async function handleSignOut() {
	const { error } = await supabase.auth.signOut();
	if (error) {
		console.log({ error });
		throw error;
	}
}

export async function handleGetOAuthUrl(provider) {
	const { data, error } = await supabase.auth.signInWithOAuth({
		provider,
	});
	if (error) {
		console.log({ error });
		throw error;
	}
	return data.url;
}

export async function handleSetGoogleOAuthSession(access_token, refresh_token) {
	const { data, error } = await supabase.auth.setSession({ access_token, refresh_token });
	if (error) {
		console.log({ error });
		throw error;
	}
	return data;
}
