import { useContext } from "react";
import { StandardInput } from "../basic/inputs";
import { ColumnBox, RowBox } from "../boxes";
import { Text } from "../text";
import UploadBox from "../input/upload/upload-box";
import { GameManagementContext } from "../../context/game-management-context";
import { FolderIcon } from "../icons";
import { getFileNameFromURL } from "../../resources/s3.resource";

const EditPlayerAmounts = () => {
	const { waitingForApproval, info } = useContext(GameManagementContext);
	return (
		<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
			<Text size={12}>Enter player amount</Text>
			<RowBox width={"100%"} gap={15}>
				<StandardInput
					placeholder={"Min"}
					value={info.editMinPlayers}
					onChange={(e) => {
						e.preventDefault();
						let value = parseInt(e.target.value);
						info.setMinPlayers(value);
					}}
					onBlur={(e) => {
						let value = parseInt(e.target.value);
						if (!value) {
							value = 1;
						}
						if (value < 1) {
							value = 1;
						}
						if (value > 999) {
							value = 999;
						}
						if (value > info.editMaxPlayers) {
							value = info.editMaxPlayers;
						}
						info.setMinPlayers(value);
					}}
					disableUnderline={true}
					type={"number"}
					disabled={waitingForApproval}
				/>
				<StandardInput
					placeholder={"Max"}
					value={info.editMaxPlayers}
					onChange={(e) => {
						e.preventDefault();
						let value = parseInt(e.target.value);
						info.setMaxPlayers(value);
					}}
					onBlur={(e) => {
						let value = parseInt(e.target.value);
						if (!value) {
							value = 999;
						}
						if (value < 1) {
							value = 1;
						}
						if (value > 999) {
							value = 999;
						}
						if (value < info.editMinPlayers) {
							value = info.editMinPlayers;
						}
						info.setMaxPlayers(value);
					}}
					disableUnderline={true}
					type={"number"}
					disabled={waitingForApproval}
				/>
			</RowBox>
		</ColumnBox>
	);
};

const GameInfoSection = ({ can_edit_title, onCoverImageChange, onFaviconChange }) => {
	const { waitingForApproval, info } = useContext(GameManagementContext);
	return (
		<>
			{can_edit_title ? (
				<RowBox width={"100%"} gap={25}>
					<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
						<Text size={12}>What is your game called?</Text>
						<StandardInput
							placeholder={"Game title"}
							value={info.editGameTitle}
							onChange={(e) => {
								e.preventDefault();
								let value = e.target.value;
								if (value.length > 50) {
									value = value.slice(0, 50);
								}
								info.setGameTitle(value);
							}}
							disableUnderline={true}
							disabled={waitingForApproval}
						/>
					</ColumnBox>
					<EditPlayerAmounts />
				</RowBox>
			) : (
				<RowBox width={"100%"} gap={25}>
					<EditPlayerAmounts />
					<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }} />
				</RowBox>
			)}
			<RowBox width={"100%"} gap={25} align={"flex-start"}>
				<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
					<RowBox width={"100%"} justify={"space-between"}>
						<Text size={12}>Cover image</Text>
						<Text size={12}>(350x350px)</Text>
					</RowBox>
					<UploadBox id={0} accept={"image/*"} change={onCoverImageChange} waitingForApproval={waitingForApproval} />
					{info.editCoverImageURL && !info.editCoverImage ? (
						<RowBox gap={5}>
							<FolderIcon />
							<Text size={12}>{getFileNameFromURL(info.editCoverImageURL)}</Text>
						</RowBox>
					) : null}
					{info.editCoverImage ? (
						<RowBox gap={5}>
							<FolderIcon />
							<Text size={12}>{info.editCoverImage.name}</Text>
						</RowBox>
					) : null}
				</ColumnBox>
				<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
					<RowBox width={"100%"} justify={"space-between"}>
						<Text size={12}>Favicon</Text>
						<Text size={12}>(32x32px)</Text>
					</RowBox>
					<UploadBox id={1} accept={"image/*"} change={onFaviconChange} waitingForApproval={waitingForApproval} />
					{info.editFaviconURL && !info.editFavicon ? (
						<RowBox gap={5}>
							<FolderIcon />
							<Text size={12}>{getFileNameFromURL(info.editFaviconURL)}</Text>
						</RowBox>
					) : null}
					{info.editFavicon ? (
						<RowBox gap={5}>
							<FolderIcon />
							<Text size={12}>{info.editFavicon.name}</Text>
						</RowBox>
					) : null}
				</ColumnBox>
			</RowBox>
			<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
				<RowBox width={"100%"} justify={"space-between"}>
					<Text size={12}>Description</Text>
					<Text size={12}>Max: 350 characters</Text>
				</RowBox>
				<StandardInput
					placeholder={"Describe your game"}
					value={info.editDescription}
					onChange={(e) => {
						e.preventDefault();
						let value = e.target.value;
						if (value.length > 350) {
							value = value.slice(0, 350);
						}
						info.setDescription(value);
					}}
					disableUnderline={true}
					multiline
					rows={2}
					style={{ paddingTop: 5, paddingBottom: 5 }}
					disabled={waitingForApproval}
				/>
			</ColumnBox>
			<ColumnBox align={"flex-start"} gap={10} width={"100%"}></ColumnBox>
		</>
	);
};

export default GameInfoSection;
