import { useContext } from "react";
import { ColumnBox, RowBox } from "../boxes";
import { GameManagementContext } from "../../context/game-management-context";
import UploadBox from "../input/upload/upload-box";
import { Text } from "../text";
import { colors } from "../../styles/styles";
import BuildFileHolder from "../basic/build-file-container";

const GameAssetsSection = ({}) => {
	const { waitingForApproval, assets } = useContext(GameManagementContext);
	return (
		<>
			<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
				<Text size={12}>Upload Unity build files</Text>
				<UploadBox
					id={2}
					accept={".js, .data, .wasm, .unityweb, .json, .zip"}
					mult={true}
					change={assets.onUnityLoaderChange}
					waitingForApproval={waitingForApproval}
				/>
				<RowBox width={"100%"} gap={5} style={{ overflowX: "scroll" }} justify={"flex-start"}>
					<BuildFileHolder url={assets.editUnityDataURL} file={assets.editUnityData} fileExtension={".data"} />
					<BuildFileHolder url={assets.editUnityFrameworkURL} file={assets.editUnityFramework} fileExtension={".framework.js"} />
					<BuildFileHolder url={assets.editUnityLoaderURL} file={assets.editUnityLoader} fileExtension={".loader.js"} />
					<BuildFileHolder url={assets.editUnityCodeURL} file={assets.editUnityCode} fileExtension={".wasm"} />
				</RowBox>
			</ColumnBox>
			<Text size={14}>
				Need to upload a StreamingAssets folder? Upload a <span style={{ color: colors.redMain, fontWeight: 700 }}>.zip </span>
				of the folder titled <span style={{ color: colors.redMain, fontWeight: 700 }}>"StreamingAssets"</span>{" "}
			</Text>
			<Text size={14}>
				Need help? Use our{" "}
				<a
					href={"https://docs.maddergames.com/quickstart/introduction"}
					target={"_blank"}
					style={{ fontWeight: 700, color: colors.redMain, textDecoration: "none" }}
				>
					Quickstart Tutorial
				</a>{" "}
				or check out the{" "}
				<a
					href={"https://docs.maddergames.com/"}
					target={"_blank"}
					style={{ fontWeight: 700, color: colors.redMain, textDecoration: "none" }}
				>
					Documentation
				</a>
			</Text>
		</>
	);
};

export default GameAssetsSection;
