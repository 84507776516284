import { useContext } from "react";
import { GameManagementContext } from "../../context/game-management-context";
import { InfoBox } from "../basic/containers";
import { Text } from "../text";
import { ColumnBox, RowBox } from "../boxes";
import { RedButton, WhiteButton } from "../basic/inputs";
import { colors } from "../../styles/styles";

const GameSubmitSection = ({ handleClickTest }) => {
	const {
		waitingForApproval,
		gameVersions: { versions, intVersion, updateVersion },
		submission: { saveEnabled, testEnabled, onSave, submitEnabled },
	} = useContext(GameManagementContext);

	const onSubmit = async () => {
		await updateVersion({ status: "Waiting for approval" }, intVersion);
	};

	const onCancel = async () => {
		await updateVersion({ status: "In progress" }, intVersion);
	};

	return (
		<InfoBox>
			<Text weight={700}>Test and Submit</Text>
			<RowBox width={"100%"} gap={25}>
				<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
					<Text size={12}>Test your game</Text>
					<WhiteButton
						width={"100%"}
						disableRipple={true}
						style={{
							border: "1px solid var(--black-50, rgba(0, 0, 0, 0.50))",
						}}
						disabled={!testEnabled}
						onClick={handleClickTest}
					>
						<Text size={14} weight={700} color={colors.black50}>
							Open test
						</Text>
					</WhiteButton>
				</ColumnBox>
				<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
					<Text size={12}>{versions[intVersion - 1].title ? versions[intVersion - 1].title : "Your game"}, version 1</Text>
					<RowBox width={"100%"} gap={15}>
						<WhiteButton
							width={"100%"}
							disableRipple={true}
							style={{
								border: "1px solid var(--black-50, rgba(0, 0, 0, 0.50))",
							}}
							disabled={!saveEnabled || waitingForApproval}
							onClick={onSave}
						>
							<Text size={14} weight={700} color={colors.black50}>
								Save
							</Text>
						</WhiteButton>
						{versions[intVersion - 1].status === "In progress" || versions[intVersion - 1].status === "Rejected" ? (
							<RedButton width={"100%"} disableRipple={true} disabled={!submitEnabled} onClick={onSubmit}>
								<Text size={14} weight={700} color={colors.whiteMain}>
									Submit
								</Text>
							</RedButton>
						) : (
							<RedButton width={"100%"} disableRipple={true} disabled={!waitingForApproval} onClick={onCancel}>
								<Text size={14} weight={700} color={colors.whiteMain}>
									Cancel
								</Text>
							</RedButton>
						)}
					</RowBox>
				</ColumnBox>
			</RowBox>
			<Text size={14}>
				View our{" "}
				<a
					href={"https://docs.maddergames.com/madder-store-guidelines"}
					target={"_blank"}
					style={{ fontWeight: 700, color: colors.redMain, textDecoration: "none" }}
				>
					Madder Store Guidelines
				</a>{" "}
			</Text>
		</InfoBox>
	);
};

export default GameSubmitSection;
