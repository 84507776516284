import { StandardInput } from "./inputs";
import { useMemo, useState } from "react";
import formatDollar from "../../utils/formatting/format-dollar";

const DollarInput = ({ value, onChange, disabled }) => {
	const [editingDecimal, setEditingDeciaml] = useState(false);
	const [lastDigitIsZero, setLastDigitIsZero] = useState(0);
	const [showFixed, setShowFixed] = useState(true);
	const decVal = useMemo(() => value / 100, [value]);
	const dVal = useMemo(
		() =>
			showFixed
				? "$" + decVal.toFixed(2)
				: lastDigitIsZero === 1
					? decVal + ".0"
					: lastDigitIsZero === 2
						? decVal + ".00"
						: editingDecimal
							? decVal + "."
							: decVal,
		[editingDecimal, lastDigitIsZero, showFixed, decVal]
	);
	return (
		<StandardInput
			placeholder={"0.00"}
			value={dVal}
			onChange={(e) => {
				e.preventDefault();
				const val = formatDollar(e.target.value, dVal) + "";
				if (val[val.length - 1] === ".") {
					setEditingDeciaml(true);
				} else {
					setEditingDeciaml(false);
				}
				if (val[val.length - 1] === "0" && val[val.length - 2] === ".") {
					setLastDigitIsZero(1);
				} else if (val[val.length - 1] === "0" && val[val.length - 2] === "0" && val[val.length - 3] === ".") {
					setLastDigitIsZero(2);
				} else {
					setLastDigitIsZero(0);
				}
				onChange(val * 100);
			}}
			onFocus={() => {
				setShowFixed(false);
			}}
			onBlur={() => {
				setShowFixed(true);
			}}
			disableUnderline={true}
			style={{ paddingTop: 5, paddingBottom: 5 }}
			disabled={disabled}
		/>
	);
};

export default DollarInput;
