import { Link } from "react-router-dom";
import { ColumnBox, PageBox, RowBox } from "../boxes";
import { Text } from "../text";
import DeveloperNavBar from "../layout/developer-nav-bar";
import { FolderIcon } from "../icons";
import { getFileNameFromURL } from "../../resources/s3.resource";
import { colors } from "../../styles/styles";
import { useContext, useState } from "react";
import { GameManagementContext } from "../../context/game-management-context";
import { Box, Button } from "@mui/material";
import { InfoBox } from "../basic/containers";
import styled from "styled-components";
import { formatDateTimestamptzToMMDDYYYY } from "../../lib/global-functions";
import BuildFileHolder from "../basic/build-file-container";
import Footer from "../footer";

const MobileVersionButton = styled(Button)({
	position: "relative",
	padding: "5px 15px",
	borderRadius: 10,
	background: colors.grayMain,
	textTransform: "none",
	"&:hover": {
		background: colors.grayMain,
	},
	"&:active": {
		background: "#D9D9D9",
	},
});

const MobileVersionPopupBox = styled(Box)({
	position: "absolute",
	top: 35,
	right: 0,
	padding: 15,
	width: 250,
	borderRadius: 10,
	border: "0.5px solid var(--black-50, rgba(0, 0, 0, 0.50))",
	backgroundColor: colors.whiteMain,
	zIndex: 2,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: 15,
});

const MobileVersionPopup = () => {
	const {
		gameVersions: { v, versions, gameId, intVersion },
	} = useContext(GameManagementContext);
	return (
		<MobileVersionPopupBox>
			{versions.map((v, i) => {
				return (
					<Link to={`/${gameId}/${v.version}`} key={i}>
						<RowBox
							width={"calc(100% - 30px)"}
							gap={15}
							justify={"space-between"}
							style={{
								padding: "5px 15px",
								borderRadius: 10,
								backgroundColor: intVersion === v.version ? colors.grayMain : "transparent",
								cursor: "pointer",
							}}
						>
							<Text weight={700} color={colors.black50}>
								V{v.version}
							</Text>
							<RowBox gap={5}>
								{v.status === "Approved" ? (
									<Text size={12} color={colors.black50}>
										{formatDateTimestamptzToMMDDYYYY(v.last_updated)}
									</Text>
								) : null}
								<Text
									size={12}
									weight={600}
									color={v.status === "Approved" ? "#92C262" : v.status === "In progress" ? colors.blackMain : colors.redMain}
								>
									{v.status}
								</Text>
								{v.status === "Approved" ? (
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6 12.5C9.31371 12.5 12 9.81371 12 6.5C12 3.18629 9.31371 0.5 6 0.5C2.68629 0.5 0 3.18629 0 6.5C0 9.81371 2.68629 12.5 6 12.5ZM5.11713 10.1L10.1333 4.0437L9.08724 3.16664L5.02563 8.07043L2.82947 5.86082L1.86663 6.82956L5.11713 10.1Z"
											fill="#92C262"
										/>
									</svg>
								) : null}
							</RowBox>
						</RowBox>
					</Link>
				);
			})}
		</MobileVersionPopupBox>
	);
};

const MobileDeveloperGame = () => {
	const [mobileVersionPopup, setMobileVersionPopup] = useState(false);
	const {
		gameVersions: { v, versions, gameId, intVersion },
	} = useContext(GameManagementContext);

	if (versions.length === 0) {
		return (
			<PageBox>
				<DeveloperNavBar />
			</PageBox>
		);
	}
	return (
		<PageBox>
			<DeveloperNavBar />
			<Box style={{ height: 44 + 15 }} />
			<RowBox width={"calc(100vw - 30px)"} justify={"space-between"}>
				<Text size={24} weight={700}>
					{v.title ? v.title : "My Game"}
				</Text>
				<MobileVersionButton disabled={versions.length < 2} disableRipple={true} onClick={() => setMobileVersionPopup(!mobileVersionPopup)}>
					<Text>Version {intVersion}</Text>
					{mobileVersionPopup ? <MobileVersionPopup /> : null}
				</MobileVersionButton>
			</RowBox>
			<Box style={{ height: 15 }} />
			<InfoBox style={{ padding: 15, gap: 15, width: "calc(100vw - 60px)" }}>
				<Text weight={700}>Game Info</Text>
				<Text size={12}>
					<b>Player amount:</b> {v.min_players}-{v.max_players}
				</Text>
				<RowBox width={"100%"} gap={25}>
					<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
						<Text size={12}>
							<b>Cover image:</b>
						</Text>
						{v.thumbnail_url ? (
							<RowBox gap={5}>
								<FolderIcon />
								<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
									{getFileNameFromURL(v.thumbnail_url)}
								</Text>
							</RowBox>
						) : (
							<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
								No cover image
							</Text>
						)}
					</ColumnBox>
					<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
						<Text size={12}>
							<b>Favicon:</b>
						</Text>
						{v.mini_icon_url ? (
							<RowBox gap={5}>
								<FolderIcon />
								<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
									{getFileNameFromURL(v.mini_icon_url)}
								</Text>
							</RowBox>
						) : (
							<Text size={12} color={colors.black50} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
								No favicon
							</Text>
						)}
					</ColumnBox>
				</RowBox>
				<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
					<Text size={12}>
						<b>Description:</b>
					</Text>
					<Text size={12}>{v.description ? v.description : "No description"}</Text>
				</ColumnBox>
			</InfoBox>
			<Box style={{ height: 15 }} />
			<InfoBox style={{ padding: 15, gap: 15, width: "calc(100vw - 60px)" }}>
				<Text weight={700}>Game Assets</Text>
				<Text size={12}>
					<b>Unity build files:</b>
				</Text>
				<RowBox width={"100%"} gap={5} style={{ overflowX: "scroll" }} justify={"flex-start"}>
					<BuildFileHolder url={v.unity_data_url} fileExtension={".data"} />
					<BuildFileHolder url={v.unity_framework_url} fileExtension={".framework.js"} />
					<BuildFileHolder url={v.unity_loader_url} fileExtension={".loader.js"} />
					<BuildFileHolder url={v.unity_code_url} fileExtension={".wasm"} />
				</RowBox>
			</InfoBox>
			<Box style={{ flex: 1, minHeight: 15 }} />
			<Footer />
		</PageBox>
	);
};

export default MobileDeveloperGame;
