import { colors } from "../../styles/styles";

const { styled, Input, Button } = require("@mui/material");

export const StandardInput = styled(Input)({
	width: "100%",
	padding: "0px 15px",
	borderRadius: 10,
	backgroundColor: colors.grayMain,
	color: colors.blackMain,
	fontSize: 14,
	fontWeight: 400
});

export const WhiteButton = styled(Button)(({ width = "auto" }) => ({
	width: width,
	padding: "5px 15px",
	borderRadius: 10,
	background: colors.whiteMain,
	textTransform: "none",
	"&:hover": {
		background: colors.whiteMain
	},
	"&:active": {
		background: colors.grayMain
	},
	"&:disabled": {
		opacity: 0.5
	}
}));

export const RedButton = styled(Button)(({ width = "auto" }) => ({
	width: width,
	padding: "5px 15px",
	borderRadius: 10,
	background: colors.redMain,
	textTransform: "none",
	"&:hover": {
		background: colors.redMain
	},
	"&:active": {
		background: colors.redSecondary
	},
	"&:disabled": {
		opacity: 0.5
	}
}));
