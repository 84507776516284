import { Box, IconButton, styled, Typography, useMediaQuery } from "@mui/material";
import { colors, NotVisibleEye, VisibileEye } from "../../styles/styles";
import { ColumnBox, HorizontalLine, PageBox, RowBox } from "../../components/boxes";
import Footer from "../../components/footer";
import { Text } from "../../components/text";
import { MainButton } from "../../components/buttons";
import AppleLogin from "react-apple-login";
import { useContext, useState } from "react";
import { Input } from "../../components/inputs";
import { supabase } from "../../lib/supabase";
import { handleGetOAuthUrl } from "../../handlers/user";
import { GlobalContext } from "../../context/global-context";
import { useNavigate } from "react-router-dom";

const InnerPageBox = styled(Box)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flex: 1,
	width: "100%",
	backgroundColor: colors.grayMain,
	position: "relative",
});

const InfoBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "25px 50px",
	gap: 25,
	borderRadius: 25,
	backgroundColor: colors.whiteMain,
	boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 7px 0px rgba(0, 0, 0, 0.20)",
});

const BoxList = styled(Box)({
	width: 361,
	maxWidth: "calc(100vw - 30px)",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: 15,
});

const OAuthButton = styled(Box)({
	width: 50,
	height: 50,
	borderRadius: 25,
	backgroundColor: colors.whiteMain,
	filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.20))",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	cursor: "pointer",
	"&:active": {
		backgroundColor: colors.grayMain,
	},
});

function Authenticate() {
	const isMobile = useMediaQuery("(max-width: 600px)");
	const breakWidth = useMediaQuery("(max-width: 370px)");
	const navigate = useNavigate();
	const {
		auth: { user, updateUser },
	} = useContext(GlobalContext);
	const [option, setOption] = useState("sign_in");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [displayPassword, setDisplayPassword] = useState(false);
	const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
	const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
	const [resetStatus, setResetStatus] = useState(false);

	const handleSignUp = async () => {
		const { data, error } = await supabase.auth.signUp({
			email: email,
			password: password,
		});
		if (error) {
			console.log({ error });
			if (error.message.includes("Password")) {
				setPasswordError(error.message);
				setEmailError(null);
			} else if (error.message.includes("email")) {
				setEmailError(error.message);
				setPasswordError(null);
			} else if (error.message.includes("already")) {
				setEmailError(error.message);
				setPasswordError(null);
			} else {
				setPasswordError(error.message);
			}
			return;
		}
		const { user } = data.session;
		try {
			updateUser({ id: user.id, email: user.email });
			navigate("/");
		} catch (e) {
			console.log({ e });
		}
	};

	const handleSignIn = async () => {
		const { data, error } = await supabase.auth.signInWithPassword({
			email: email,
			password: password,
		});
		if (error) {
			console.log({ error });
			if (error.message.includes("credentials")) {
				setPasswordError(error.message);
				setEmailError(null);
			} else {
				setPasswordError(error.message);
			}
			return;
		}
		const { user } = data.session;
		try {
			updateUser({ id: user.id, email: user.email });
			navigate("/");
		} catch (e) {
			console.log({ e });
		}
	};

	const handleSendResetPassword = async () => {
		await supabase.auth
			.resetPasswordForEmail(email, {
				redirectTo: `https://developers.maddergames.com/reset-password`,
			})
			.then(() => {
				setEmail("");
				setResetStatus(true);
			});
	};

	const onSignInWithOAuth = async (provider) => {
		try {
			const url = await handleGetOAuthUrl(provider);
			if (!url) return;

			console.log({ url });
			// Redirect user to Google authentication page
			window.location.href = url;
		} catch (e) {
			console.error(e);
		}
	};

	const getForm = () => {
		if (option === "create_account") {
			return (
				<>
					<Text size={28} weight={700}>
						Create account
					</Text>
					{breakWidth ? (
						<ColumnBox gap={3}>
							<Text>Already have an account?</Text>
							<Text
								weight={700}
								color={colors.redMain}
								style={{
									cursor: "pointer",
								}}
								onClick={() => setOption("sign_in")}
							>
								Sign In
							</Text>
						</ColumnBox>
					) : (
						<RowBox gap={5}>
							<Text>Already have an account?</Text>
							<Text
								weight={700}
								color={colors.redMain}
								style={{
									cursor: "pointer",
								}}
								onClick={() => setOption("sign_in")}
							>
								Sign In
							</Text>
						</RowBox>
					)}
					<BoxList>
						<Input width={"calc(100% - 50px)"} placeholder={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
						{emailError ? (
							<RowBox width={"100%"} justify={"flex-start"}>
								<Text color={colors.redMain} size={14}>
									{emailError}
								</Text>
							</RowBox>
						) : null}
						<Box sx={{ position: "relative", width: "100%" }}>
							<Input
								width={"calc(100% - 50px)"}
								placeholder={"Password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								type={displayPassword ? "text" : "password"}
							/>
							<IconButton onClick={() => setDisplayPassword(!displayPassword)} sx={{ position: "absolute", right: 10, top: 5 }}>
								{displayPassword ? <VisibileEye /> : <NotVisibleEye />}
							</IconButton>
						</Box>
						{passwordError ? (
							<RowBox width={"100%"} justify={"flex-start"}>
								<Text color={colors.redMain} size={14}>
									{passwordError}
								</Text>
							</RowBox>
						) : null}
						<Box sx={{ position: "relative", width: "100%" }}>
							<Input
								width={"calc(100% - 50px)"}
								placeholder={"Password"}
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								type={displayConfirmPassword ? "text" : "password"}
							/>
							<IconButton
								onClick={() => setDisplayConfirmPassword(!displayConfirmPassword)}
								sx={{ position: "absolute", right: 10, top: 5 }}
							>
								{displayConfirmPassword ? <VisibileEye /> : <NotVisibleEye />}
							</IconButton>
						</Box>
						{confirmPasswordError ? (
							<RowBox width={"100%"} justify={"flex-start"}>
								<Text color={colors.redMain} size={14}>
									{confirmPasswordError}
								</Text>
							</RowBox>
						) : null}
						<MainButton
							width={"100%"}
							disabled={email === "" || password === "" || password !== confirmPassword}
							onClick={handleSignUp}
							disableRipple={true}
						>
							<Text weight={700} color={colors.whiteMain}>
								Create
							</Text>
						</MainButton>
					</BoxList>
					<RowBox gap={12} justify={"space-between"} width={361} style={{ maxWidth: "calc(100vw - 30px)" }}>
						<HorizontalLine style={{ flex: 1 }} />
						<Text size={14}>Or sign up with</Text>
						<HorizontalLine style={{ flex: 1 }} />
					</RowBox>
					<RowBox gap={32}>
						<AppleLogin
							clientId={"com.dalpal07.madder-web"}
							redirectURI={"https://developers.maddergames.com"}
							responseType={"code id_token"}
							responseMode={"fragment"}
							render={({ onClick }) => (
								<OAuthButton onClick={onClick}>
									<img src={"./apple-icon.png"} alt={"Apple"} width={29} style={{ marginRight: 2 }} />
								</OAuthButton>
							)}
						/>
						<OAuthButton onClick={() => onSignInWithOAuth("google")}>
							<img src={"./google-icon.png"} alt={"Google"} width={34} />
						</OAuthButton>
					</RowBox>
				</>
			);
		} else if (option === "sign_in") {
			if (displayForgotPassword) {
				return (
					<>
						<Text size={28} weight={700} sx={{ marginTop: 5 }}>
							Forgot Password
						</Text>
						<BoxList>
							<Input width={"calc(100% - 50px)"} placeholder={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
							{emailError ? (
								<RowBox width={"100%"} justify={"flex-start"}>
									<Text color={colors.redMain} size={14}>
										{emailError}
									</Text>
								</RowBox>
							) : null}
						</BoxList>
						<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
							<MainButton width={"100%"} onClick={() => setDisplayForgotPassword(false)} disableRipple={true} bgcolor={colors.grayMain}>
								<Text weight={700} color={colors.blackMain}>
									Back
								</Text>
							</MainButton>
							<Box sx={{ minWidth: 25 }} />
							<MainButton width={"100%"} disabled={email === ""} onClick={handleSendResetPassword} disableRipple={true}>
								<Text weight={700} color={colors.whiteMain}>
									Send
								</Text>
							</MainButton>
						</Box>
						{resetStatus === true && (
							<Typography variant="subtitle2" sx={{ textAlign: "center", width: isMobile ? 300 : 400 }}>
								If the provided email matches our records, you'll receive an email with instructions on how to reset your password. Please
								check your inbox shortly.
							</Typography>
						)}
					</>
				);
			} else {
				return (
					<>
						<Text size={28} weight={700}>
							Sign in
						</Text>
						{breakWidth ? (
							<ColumnBox gap={3}>
								<Text>Don't have an account yet?</Text>
								<Text
									weight={700}
									color={colors.redMain}
									style={{
										cursor: "pointer",
									}}
									onClick={() => setOption("create_account")}
								>
									Create Account
								</Text>
							</ColumnBox>
						) : (
							<RowBox gap={5}>
								<Text>Don't have an account yet?</Text>
								<Text
									weight={700}
									color={colors.redMain}
									style={{
										cursor: "pointer",
									}}
									onClick={() => setOption("create_account")}
								>
									Create Account
								</Text>
							</RowBox>
						)}
						<BoxList>
							<Input width={"calc(100% - 50px)"} placeholder={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
							{emailError ? (
								<RowBox width={"100%"} justify={"flex-start"}>
									<Text color={colors.redMain} size={14}>
										{emailError}
									</Text>
								</RowBox>
							) : null}
							<Box sx={{ position: "relative", width: "100%" }}>
								<Input
									width={"calc(100% - 50px)"}
									placeholder={"Password"}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									type={displayPassword ? "text" : "password"}
								/>
								<IconButton onClick={() => setDisplayPassword(!displayPassword)} sx={{ position: "absolute", right: 10, top: 5 }}>
									{displayPassword ? <VisibileEye /> : <NotVisibleEye />}
								</IconButton>
							</Box>
							{passwordError ? (
								<RowBox width={"100%"} justify={"flex-start"}>
									<Text color={colors.redMain} size={14}>
										{passwordError}
									</Text>
								</RowBox>
							) : null}
							<MainButton width={"100%"} disabled={email === "" || password === ""} onClick={handleSignIn} disableRipple={true}>
								<Text weight={700} color={colors.whiteMain}>
									Login
								</Text>
							</MainButton>
							<Text color={colors.redMain} onClick={() => setDisplayForgotPassword(true)} style={{ cursor: "pointer" }}>
								Forgot Password?
							</Text>
						</BoxList>
						<RowBox gap={12} justify={"space-between"} width={361} style={{ maxWidth: "calc(100vw - 30px)" }}>
							<HorizontalLine style={{ flex: 1 }} />
							<Text size={14}>Or sign in with</Text>
							<HorizontalLine style={{ flex: 1 }} />
						</RowBox>
						<RowBox gap={32}>
							<AppleLogin
								clientId={"com.dalpal07.madder-web"}
								redirectURI={"https://developers.maddergames.com"}
								responseType={"code id_token"}
								responseMode={"fragment"}
								render={({ onClick }) => (
									<OAuthButton onClick={onClick}>
										<img src={"./apple-icon.png"} alt={"Apple"} width={29} style={{ marginRight: 2 }} />
									</OAuthButton>
								)}
							/>
							<OAuthButton onClick={() => onSignInWithOAuth("google")}>
								<img src={"./google-icon.png"} alt={"Google"} width={34} />
							</OAuthButton>
						</RowBox>
					</>
				);
			}
		}
	};

	if (isMobile) {
		return (
			<PageBox>
				<Box style={{ height: 75 }} />
				<ColumnBox gap={25}>{getForm()}</ColumnBox>
				<Box style={{ flex: 1 }} />
				<Footer />
			</PageBox>
		);
	}
	return (
		<PageBox>
			<InnerPageBox>
				<InfoBox sx={{ maxWidth: 370 }}>{getForm()}</InfoBox>
			</InnerPageBox>
			<Box style={{ height: 25 }} />
			<Footer />
		</PageBox>
	);
}

export default Authenticate;
