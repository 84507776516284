import { useEffect, useMemo, useState } from "react";
import useAuthToken from "./use-auth-token";
import useLoadingBasics, { defaultLoadingBasics } from "../use-loading-basics";
import { handleGetSession, supabase } from "../../lib/supabase";
import { handleAppleOAuthCallback } from "../../resources/auth.resource";
import { handleSetGoogleOAuthSession } from "../../handlers/user";

export const defaultAuth = { supaToken: null, user: null, status: defaultLoadingBasics };

const useAuth = () => {
	const [user, setUser] = useState(null);
	const status = useLoadingBasics("NOT_LOADED");

	//need to implement this later so that we can easily use this properly
	const [signedIn, setSignedIn] = useState(false);
	const tokenAuth = useAuthToken();

	//to be implemented later when the dev portal needs more authentication
	const login = async () => {};

	const loading = useMemo(() => {
		return status.name === "LOADING" || status.name === "NOT_LOADED";
	}, [status]);

	const logout = async () => {
		const { error } = await supabase.auth.signOut();
		setSignedIn(false);
		setUser(null);
		//sign the token out too
		tokenAuth.logout();
		if (error) {
			console.error(error);
		}
	};

	const getUser = async () => {
		try {
			status.loading();
			const data = await handleGetSession();
			const userInfo = data?.session?.user;
			console.log(data);
			if (data?.session?.access_token) {
				tokenAuth.updateToken(data.session.access_token, data.session.expires_at, data.session.refresh_token);
			}
			if (userInfo && userInfo?.id) {
				status.success();
				setUser(userInfo);
				setSignedIn(true);
			} else {
				//if there was no user info or session then run an oAuth Check to make sure the user wasn't trying to sign in via oAuth
				const foundOAuth = await oAuth();
				if (!foundOAuth) {
					status.noData();
				} else {
					getUserToken();
				}
			}
		} catch (error) {
			console.log({ error });
			status.error();
			return;
		} finally {
		}
	};

	const updateUser = async (nUser) => {
		if (nUser?.id && nUser?.email) {
			setSignedIn(true);
		}
		setUser(nUser);
	};
	//do a check to see if the user is trying to sign in via oAuth which passes in quries to the url
	const oAuth = async () => {
		let foundUser = false;
		if (!user?.id) {
			const url = new URL(window.location.href);
			const parts = url.toString().split("#");

			if (parts.length > 1) {
				const fragment = parts[1];

				const params = new URLSearchParams(fragment);

				const type = params.get("type");
				const error_code = params.get("error_code");
				const error_description = params.get("error_description");

				const access_token = params.get("access_token");
				const refresh_token = params.get("refresh_token");

				const code = params.get("code");
				const id_token = params.get("id_token");

				if (type === "recovery") {
					tokenAuth.updateToken(access_token, null, refresh_token);
					status.error("Error " + error_code + ": " + error_description);
				} else if (access_token && refresh_token) {
					const { user } = await handleSetGoogleOAuthSession(access_token, refresh_token);
					if (user) {
						setUser({ id: user.id, email: user.email });
						setSignedIn(true);
						// we can stop the auth loading processes and load things with this user as we found a user
						status.success();
						foundUser = true;
					}
				} else if (code && id_token) {
					const user = await handleAppleOAuthCallback(id_token);
					if (user && user?.id) {
						setUser({ id: user.id, email: user.email });
						setSignedIn(true);
						status.success();
						foundUser = true;
					}
				} else if (error_code || error_description) {
					status.error("Error " + error_code + ": " + error_description);
				}
				//clean up the url so we don't have the token just sitting in the url
				const cleanUrl = parts[0];
				window.history.replaceState(null, "", cleanUrl);
				//return back that we found a user
				return foundUser;
			}
		}
		return foundUser;
	};

	const getUserToken = async () => {
		try {
			const data = await handleGetSession();
			console.log(data);
			if (data?.session?.access_token) {
				tokenAuth.updateToken(data.session.access_token, data.session.expires_at, data.session.refresh_token);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	// const getUserInfo = async () => {
	// 	const { data: userData, error: userError } = await supabase.auth.getUser();
	// 	if (userError) {
	// 		console.error(userError);
	// 		return null;
	// 	}
	// 	if (userData.user) {
	// 		setUser(userData.user);
	// 	}
	// };

	// useEffect(() => {
	// 	if (tokenAuth.token && !user) {
	// 		getUserInfo();
	// 	}
	// }, [tokenAuth.token]);

	return { updateSupaToken: tokenAuth.updateToken, supaToken: tokenAuth.token, user, status, signedIn, logout, updateUser, loading };
};

export default useAuth;
