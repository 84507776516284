import { Button, styled } from "@mui/material";
import { colors } from "../styles/styles";

export const MainButton = styled(Button)(({ width = "auto", bgcolor = colors.redMain, activebgcolor = colors.redSecondary }) => ({
	padding: "15px 50px",
	borderRadius: 100,
	background: bgcolor,
	textTransform: "none",
	width: width,
	"&:hover": {
		background: bgcolor
	},
	"&:active": {
		background: activebgcolor
	},
	"&:disabled": {
		opacity: 0.5
	}
}));

export const MainButtonMobile = styled(MainButton)({
	padding: "12px 32px"
});
