import { ColumnBox, PageBox } from "../../components/boxes";
import { Box } from "@mui/material";
import Footer from "../../components/footer";
import { Text } from "../../components/text";
import { colors } from "../../styles/styles";
import DeveloperNavBar from "../../components/layout/developer-nav-bar";

export default function StarterTutorial() {
	return (
		<PageBox>
			<DeveloperNavBar />
			<Box style={{ height: 44 }} />
			<ColumnBox width={"calc(100vw - 30px)"} gap={15} style={{ maxWidth: 900, padding: 15 }}>
				<Text size={24} weight={700} align={"center"}>
					Madder Starter Package Tutorial
				</Text>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Introduction
				</Text>
				<Text align={"center"}>
					Welcome to the Madder Starter Package Tutorial! This tutorial will guide you through the process of setting up your game for
					distribution on the Madder platform. It includes all of the necessary integration code that you'll need.
				</Text>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Controller
				</Text>
				<Text align={"center"}>You will be connecting to Madder's default stock controller. Use the following picture for reference:</Text>
				<img
					src={"../madder-stock-controller.png"}
					alt={"Madder Stock Controller"}
					style={{ width: "100%", maxWidth: 500, borderRadius: 25 }}
				/>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Prerequisites
				</Text>
				<Text align={"center"}>Before you begin, make sure you do the following:</Text>
				<ColumnBox gap={15} align={"flex-start"}>
					<Text>
						1. Download{" "}
						<a href={"https://unity.com/download"} target="_blank" style={{ color: colors.redMain }}>
							Unity Hub
						</a>
					</Text>
					<Text>
						2. Download the{" "}
						<a href={"/MadderStarterPackage.unitypackage"} style={{ color: colors.redMain }}>
							Madder Starter Package
						</a>
					</Text>
				</ColumnBox>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Importing and using the Madder Starter Package
				</Text>
				<Text align={"center"}>
					The following video will guide you through the process of importing and using the Madder Starter Package:
				</Text>
				<iframe
					src="https://player.vimeo.com/video/930357087?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					title="Madder Starter Package Tutorial (part 1)"
					style={{
						border: "none",
						width: 870,
						maxWidth: "calc(100vw - 30px)",
						height: 489,
						maxHeight: "calc((100vw - 30px) * 0.5625)"
					}}
				/>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Uploading your game to Madder
				</Text>
				<Text align={"center"}>
					The following video will guide you through the process of building your game and uploading it to Madder:
				</Text>
				<iframe
					src="https://player.vimeo.com/video/933034485?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					title="Madder Starter Package Tutorial (part 2)"
					style={{
						border: "none",
						width: 870,
						maxWidth: "calc(100vw - 30px)",
						height: 489,
						maxHeight: "calc((100vw - 30px) * 0.5625)"
					}}
				/>
				<Box style={{ height: 1 }} />
				<Text weight={700} align={"center"}>
					Conclusion
				</Text>
				<Text align={"center"}>
					Great work making it to the end of the tutorial! If you have any questions or need help getting your game approved for
					distribution on Madder, visit the{" "}
					<a href={"/contact"} target={"_blank"} style={{ color: colors.redMain }}>
						Contact
					</a>{" "}
					page.
				</Text>
			</ColumnBox>
			<Box style={{ flex: 1, minHeight: 10 }} />
			<Footer />
		</PageBox>
	);
}
