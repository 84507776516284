import { useState } from "react";
import useLoadingBasics, { defaultLoadingBasics } from "../use-loading-basics";

export const defaultGameAssetsHook = { status: defaultLoadingBasics };

const useGameAssetsHook = () => {
	const [editUnityLoader, setUnityLoader] = useState(null);
	const [editUnityLoaderURL, setUnityLoaderURL] = useState("");
	const [editUnityData, setUnityData] = useState(null);
	const [editUnityDataURL, setUnityDataURL] = useState("");
	const [editUnityFramework, setUnityFramework] = useState(null);
	const [editUnityFrameworkURL, setUnityFrameworkURL] = useState("");
	const [editUnityCode, setUnityCode] = useState(null);
	const [editUnityCodeURL, setUnityCodeURL] = useState("");
	const [editUnityJson, setUnityJson] = useState(null);
	const [editUnityJsonURL, setUnityJsonURL] = useState("");
	const [editUnityMemory, setUnityMemory] = useState(null);
	const [editUnityMemoryURL, setUnityMemoryURL] = useState("");
	const [editUnityZip, setUnityZip] = useState(null);
	const [editUnityZipURL, setUnityZipURL] = useState("");
	const status = useLoadingBasics();

	const update = (version) => {
		setUnityLoaderURL(version.unity_loader_url);
		setUnityDataURL(version.unity_data_url);
		setUnityFrameworkURL(version.unity_framework_url);
		setUnityCodeURL(version.unity_code_url);
		setUnityJsonURL(version.unity_json_url);
		setUnityZipURL(version.unity_zip_url);
	};

	const onUnityLoaderChange = (e) => {
		const { files } = e.target;
		if (!files) return;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.name.slice(-5) === ".data" || file.name.slice(-14) === ".data.unityweb") {
				setUnityData(file);
			} else if (file.name.slice(-13) === ".framework.js" || file.name.slice(-19) === ".framework.unityweb") {
				setUnityFramework(file);
			} else if (file.name.slice(-10) === ".loader.js" || file.name === "UnityLoader.js") {
				setUnityLoader(file);
			} else if (file.name.slice(-5) === ".wasm" || file.name.slice(-14) === ".code.unityweb") {
				setUnityCode(file);
			} else if (file.name.slice(-5) === ".json") {
				setUnityJson(file);
			} else if (file.name.slice(-16) === ".memory.unityweb") {
				setUnityMemory(file);
			} else if (file.name.slice(-4) === ".zip") {
				setUnityZip(file);
			} else {
				alert("File type not supported.");
			}
		}
	};

	return {
		status,
		editUnityLoader,
		setUnityLoader,
		editUnityLoaderURL,
		setUnityLoaderURL,
		editUnityData,
		setUnityData,
		editUnityDataURL,
		setUnityDataURL,
		editUnityFramework,
		setUnityFramework,
		editUnityFrameworkURL,
		setUnityFrameworkURL,
		editUnityCode,
		setUnityCode,
		editUnityCodeURL,
		setUnityCodeURL,
		editUnityJson,
		setUnityJson,
		editUnityJsonURL,
		setUnityJsonURL,
		editUnityMemory,
		setUnityMemory,
		editUnityMemoryURL,
		setUnityMemoryURL,
		editUnityZip,
		setUnityZip,
		editUnityZipURL,
		setUnityZipURL,
		update,
		onUnityLoaderChange,
	};
};

export default useGameAssetsHook;
