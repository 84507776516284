const { useState, useEffect } = require("react");

export const defaultLoadingProgress = { progress: 0, loading: false };

const useLoadingProgress = (data) => {
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		//check the current loading state
		let count = 0;
		let loading = 0;
		let loaded = 0;
		for (const i in data) {
			count++;
			if (data[i].status.name === "LOADING") {
				loading++;
			} else if (data[i].status.name === "SUCCESS") {
				loaded++;
			}
		}
		setLoading(loading > 0);
		setProgress(loaded / count);
	}, [data, setLoading, setProgress]);

	return { progress, loading };
};

export default useLoadingProgress;
