import { Route, Routes } from "react-router-dom";
import DeveloperPortal from "./developer-portal/index";
import StarterTutorial from "./developer-portal/starter-tutorial";
import DeveloperGame from "./developer-portal/game";
import ResetPassword from "../pages/authenticate/reset-password";
import Layout from "../components/layout/layout";
import ProtectedRoute from "../components/layout/protected-route";
import Authenticate from "../pages/authenticate";

const Router: React.FC = () => {
	return (
		<Routes>
			{/* Layout element inlcuding nav bar and other various parts */}
			<Route element={<Layout />}>
				<Route element={<ProtectedRoute />}>
					<Route index element={<DeveloperPortal />} />
					<Route path="/:gameId" element={<DeveloperGame />} />
					<Route path="/:gameId/:version" element={<DeveloperGame />} />
					<Route path="/starter-tutorial" element={<StarterTutorial />} />
				</Route>
				<Route path="reset-password" element={<ResetPassword />} />
			</Route>
		</Routes>
	);
};

export default Router;
