import { styled, Typography } from "@mui/material";
import { colors } from "../styles/styles";

export const Text = styled(Typography)(
	({ color = colors.blackMain, size = 16, weight = 400, opacity = 1, lineheight = "normal", align = "left" }) => ({
		fontFamily: "IBM Plex Sans",
		fontSize: size,
		fontWeight: weight,
		color: color,
		opacity: opacity,
		lineHeight: lineheight,
		textAlign: align
	})
);
