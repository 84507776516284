import { Unity } from "react-unity-webgl";
import { Box } from "@mui/material";

export default function UnityRender({ unityProvider, devicePixelRatio }) {
	return (
		<Box
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Unity
				unityProvider={unityProvider}
				style={{
					width: "100%",
					height: "100%"
				}}
				devicePixelRatio={devicePixelRatio}
			/>
		</Box>
	);
}
