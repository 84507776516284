import { useState } from "react";
import { handleGetControllerUrl } from "../../handlers/developers";

interface GameControllerProps {}

export interface GameControllerHook {
	url: string | null;
	getUrl: (controllerType: string) => Promise<boolean>;
}

export const defaultGameControllerHook: GameControllerHook = {
	url: null,
	getUrl: async (controllerType: string) => false,
};

const useGameController: (props: GameControllerProps) => GameControllerHook = () => {
	const [url, setControllerUrl] = useState<string | null>(null);

	const getUrl = async (controllerType: string) => {
		const url = await handleGetControllerUrl(controllerType);
		if (url) {
			setControllerUrl(url);
			return true;
		}
		return false;
	};

	return { url, getUrl };
};

export default useGameController;
