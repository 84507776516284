import React, { useEffect, useState } from "react";
import { PageBox, RowBox } from "../../components/boxes";
import { Box, IconButton, styled } from "@mui/material";
import { NotVisibleEye, VisibileEye, colors } from "../../styles/styles";
import Footer from "../../components/footer";
import { Input } from "../../components/inputs";
import { Text } from "../../components/text";
import { supabase } from "../../lib/supabase";
import { MainButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";

const InnerPageBox = styled(Box)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flex: 1,
	width: "100%",
	backgroundColor: colors.grayMain,
	position: "relative"
});

const InfoBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "25px 50px",
	gap: 25,
	borderRadius: 25,
	backgroundColor: colors.whiteMain,
	boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 7px 0px rgba(0, 0, 0, 0.20)"
});

const ResetPassword = ({}) => {
	const { refreshToken, accessToken, errorCode, errorDescription } = {
		refreshToken: "",
		accessToken: "",
		errorCode: "",
		errorDescription: ""
	};
	const navigate = useNavigate();
	const [displayReset, setDisplayReset] = useState(false);
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [displayPassword, setDisplayPassword] = useState(false);
	const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
	const [resetStatus, setResetStatus] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const [expireMsg, setExpireMsg] = useState("");

	useEffect(() => {
		if (errorCode && errorDescription) {
			setIsExpired(true);
			setExpireMsg(errorDescription);
		} else if (accessToken && refreshToken) {
			supabase.auth.refreshSession({ refresh_token: refreshToken }).then((res) => {
				if (res.data.user) {
					setDisplayReset(true);
				}
			});
		}
	}, [accessToken, refreshToken]);

	useEffect(() => {
		if (confirmPassword !== password) setConfirmPasswordError("Passwords do not match");
		else setConfirmPasswordError(null);
	}, [confirmPassword]);

	const handleReset = async () => {
		if (password !== "") {
			const { data, error } = await supabase.auth.updateUser({ password: password });

			if (data) {
				setResetStatus(true);
				setTimeout(() => {
					navigate("/");
				}, 500);
				setPassword("");
				setConfirmPassword("");
			}
			if (error) alert("There was an error updating your password.");
		} else {
			setPasswordError("Password can't be empty");
		}
	};

	return (
		<PageBox>
			<InnerPageBox>
				<InfoBox>
					{!isExpired ? (
						<>
							<Text size={28} weight={700}>
								Reset Password
							</Text>
							{displayReset && (
								<>
									<Box sx={{ position: "relative", width: "100%" }}>
										<Input
											width={"calc(100% - 50px)"}
											placeholder={"Password"}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											type={displayPassword ? "text" : "password"}
										/>
										<IconButton onClick={() => setDisplayPassword(!displayPassword)} sx={{ position: "absolute", right: 10, top: 5 }}>
											{displayPassword ? <VisibileEye /> : <NotVisibleEye />}
										</IconButton>
									</Box>
									{passwordError ? (
										<RowBox width={"100%"} justify={"flex-start"}>
											<Text color={colors.redMain} size={14}>
												{passwordError}
											</Text>
										</RowBox>
									) : null}
									<Box sx={{ position: "relative", width: "100%" }}>
										<Input
											width={"calc(100% - 50px)"}
											placeholder={"Confirm Password"}
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											type={displayConfirmPassword ? "text" : "password"}
										/>
										<IconButton
											onClick={() => setDisplayConfirmPassword(!displayConfirmPassword)}
											sx={{ position: "absolute", right: 10, top: 5 }}
										>
											{displayConfirmPassword ? <VisibileEye /> : <NotVisibleEye />}
										</IconButton>
									</Box>
									{confirmPasswordError ? (
										<RowBox width={"100%"} justify={"flex-start"}>
											<Text color={colors.redMain} size={14}>
												{confirmPasswordError}
											</Text>
										</RowBox>
									) : null}
									{resetStatus && <Text>Password updated successfully!</Text>}
									<MainButton
										width={"100%"}
										disabled={password === "" || password !== confirmPassword}
										onClick={handleReset}
										disableRipple={true}
									>
										<Text weight={700} color={colors.whiteMain}>
											Update
										</Text>
									</MainButton>
								</>
							)}
						</>
					) : (
						<Box>
							<Text size={20}>{expireMsg}</Text>
						</Box>
					)}
				</InfoBox>
			</InnerPageBox>
			<Box style={{ height: 25 }} />
			<Footer />
		</PageBox>
	);
};

export default ResetPassword;
