import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { GlobalContext } from "../../context/global-context.js";

const Layout: React.FC = () => {
	//@ts-ignore
	const { auth } = useContext(GlobalContext);

	return (
		<>
			<Outlet />
		</>
	);
};

export default Layout;
