import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";
import Authenticate from "../../pages/authenticate";

const ProtectedRoute: React.FC = () => {
	const { auth } = useContext(GlobalContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!auth.signedIn && !auth.loading) {
			navigate("/");
		}
	}, [auth]);
	//if the auth is loading and the user is not signed in wait to see if they are signed in
	if (auth.loading && !auth.signedIn) return <></>;
	//the user is not signed in and we are no longer loading the auth so since this is a protected route send them back to authenticate
	else if (!auth.signedIn) return <Authenticate></Authenticate>;

	return <Outlet></Outlet>;
};

export default ProtectedRoute;
