export const formatInt = (input, lastInput) => {
	const isDecimal = /^-?\d+(\d+)?$/;
	if (isDecimal.test(input)) {
		return parseInt(input);
	} else {
		if (input === "") {
			return 0;
		}
		return parseInt(lastInput);
	}
};

export const formatPercent = (input, lastInput, max) => {
	const newInt = formatInt(input, lastInput);
	if (max && newInt > max) {
		return max;
	}
	return newInt;
};
