import { useCallback, useState } from "react";
import useLoadingBasics, { defaultLoadingBasics } from "../use-loading-basics";

export const defaultGameInfoHook = { status: defaultLoadingBasics };

const useGameInfoHook = () => {
	const [editGameTitle, setGameTitle] = useState("");
	const [editMinPlayers, setMinPlayers] = useState(1);
	const [editMaxPlayers, setMaxPlayers] = useState(1000);
	const [editCoverImage, setCoverImage] = useState(null);
	const [editCoverImageURL, setCoverImageURL] = useState("");
	const [editFavicon, setFavicon] = useState(null);
	const [editFaviconURL, setFaviconURL] = useState("");
	const [editDescription, setDescription] = useState("");
	const status = useLoadingBasics();

	const update = useCallback(
		(version) => {
			setGameTitle(version.title);
			setMinPlayers(version.min_players);
			setMaxPlayers(version.max_players);
			setCoverImageURL(version.thumbnail_url);
			setFaviconURL(version.mini_icon_url);
			setDescription(version.description);
		},
		[setGameTitle, setMinPlayers, setMaxPlayers, setCoverImage, setCoverImageURL, setFavicon, setFaviconURL, setDescription]
	);

	return {
		status,
		editGameTitle,
		setGameTitle,
		editMinPlayers,
		setMinPlayers,
		editMaxPlayers,
		setMaxPlayers,
		editCoverImage,
		setCoverImage,
		editCoverImageURL,
		setCoverImageURL,
		editFavicon,
		setFavicon,
		editFaviconURL,
		setFaviconURL,
		editDescription,
		setDescription,
		update,
	};
};

export default useGameInfoHook;
