import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { Box } from "@mui/material";
import { Text } from "../../components/text";
import { colors } from "../../styles/styles";

export default function CustomGame({ game, socketRef, roomID, exit, afterReadyToExit, readyToExit }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const iFrameRef = useRef(null);
	const [roomCode, setRoomCode] = useState("");
	const [showCode, setShowCode] = useState(false);
	const users = useRef([]);

	useEffect(() => {
		if (!isLoaded) return;
		socketRef.current = io.connect("https://socket.madder.app");
		socketRef.current.emit("create room", game);

		socketRef.current.on("room created", (roomId) => {
			setRoomCode(roomId);
			roomID.current = roomId;
			iFrameRef.current.contentWindow.postMessage({ name: "roomCode", roomCode: roomId }, "*");
		});

		socketRef.current.on("user joined", (user) => {
			if (users.current.length === 0) {
				const message = { name: "host" };
				socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify(message) });
			} else if (users.current.length > game.minPlayers - 2) {
				const message = { name: "enoughPlayers" };
				socketRef.current.emit("message to all players", { roomId: roomID.current, message: JSON.stringify(message) });
			}
			users.current = [...users.current, user];
			iFrameRef.current.contentWindow.postMessage({ name: "addPlayer", player: user.name }, "*");
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user.name }) });
		});

		socketRef.current.on("user updated", (user) => {
			users.current.forEach((u, index) => {
				if (u.name === user.name) {
					users.current[index] = user;
					if (index === 0) {
						socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "host" }) });
					}
					if (users.current.length > game.minPlayers - 2) {
						socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "enough players" }) });
					}
				}
			});
			iFrameRef.current.contentWindow.postMessage({ name: "playerNeedsCurrentState", player: user.name }, "*");
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user.name }) });
		});

		socketRef.current.on("individual exit", (id) => {
			const user = users.current.find((user) => user.id === id);
			iFrameRef.current.contentWindow.postMessage({ name: "removePlayer", player: user.name }, "*");
			users.current = users.current.filter((user) => user.id !== id);
		});

		socketRef.current.on("message from player", ({ id, message }) => {
			const user = users.current.find((user) => user.id === id);
			iFrameRef.current.contentWindow.postMessage(
				{
					...message,
					player: user.name
				},
				"*"
			);
		});
	}, [isLoaded]);

	const handleMessage = (event) => {
		const { data } = event;
		if (data.name === "exit") {
			exit();
		} else if (data.name === "showCode") {
			setShowCode(true);
		} else if (data.name === "hideCode") {
			setShowCode(false);
		} else if (data.to === "all" && users.current.length > 0) {
			socketRef.current.emit("message to all players", { roomId: roomID.current, message: JSON.stringify(data) });
		} else if (data.to === "one") {
			const { id } = users.current.find((user) => user.name === data.player);
			socketRef.current.emit("message to player", { id, message: JSON.stringify(data) });
		}
	};

	useEffect(() => {
		window.addEventListener("message", handleMessage);
		return () => window.removeEventListener("message", handleMessage);
	}, []);

	useEffect(() => {
		if (readyToExit) {
			afterReadyToExit();
		}
	}, [readyToExit]);

	return (
		<>
			<iframe
				src={game.indexFile}
				ref={iFrameRef}
				style={{
					height: "100vh",
					width: "100vw",
					border: "none"
				}}
				onLoad={() => setIsLoaded(true)}
				allow={"autoplay"}
			/>
			<Box
				style={{
					position: "absolute",
					top: 74,
					right: 0,
					zIndex: 4,
					transform: showCode ? "translateX(0)" : "translateX(calc(100% + 21px))",
					transition: "transform 2s ease",
					width: 168,
					height: 100,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Box
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: -1
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="189" height="100" viewBox="0 0 189 100" fill="none">
						<path d="M189 0L21.3499 2.4611e-05L1.85475e-06 50.9825L21.3499 100L189 100V0Z" fill="#BB3D3D" />
					</svg>
				</Box>
				<Text size={18} color={colors.whiteMain} style={{ marginBottom: -10 }}>
					Room code:
				</Text>
				<Text size={54} color={colors.whiteMain} weight={700}>
					{roomCode}
				</Text>
			</Box>
		</>
	);
}
