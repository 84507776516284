import { getStripeAccountInfo } from "../../resources/stripe.resource";
import useLoadingBasics, { defaultLoadingBasics } from "../use-loading-basics";

const { useState, useCallback, useEffect } = require("react");

export const defaultStripeData = { accountID: null, isConnected: false, updateStripeData: () => null, status: defaultLoadingBasics };

const useStripeData = ({ token }) => {
	const [accountID, setAccountID] = useState(null);
	const [isConnected, setIsConnected] = useState(false);
	const status = useLoadingBasics();

	const updateStripeData = useCallback(
		(a, c) => {
			setAccountID(a);
			setIsConnected(c);
		},
		[setAccountID, setIsConnected]
	);

	const getStripeData = useCallback(async () => {
		//if the token exists then get the stripe data
		if (token) {
			//we are making a call to fetch the data so we are loading
			status.loading();
			const data = await getStripeAccountInfo(token);
			if (data?.error) {
				status.error(data.error);
			} else {
				status.success();
			}
			if (data?.payouts_enabled) {
				setIsConnected(true);
			}
		}
		//make sure the callback is updated when ever the token or the set states is updated or changed
	}, [setAccountID, setIsConnected, token, status]);

	//every time the token is changed call the getStripe data
	useEffect(() => {
		if (!isConnected) {
			getStripeData();
		}
	}, [token]);

	return { accountID, isConnected, updateStripeData, status };
};

export default useStripeData;
