import { styled } from "@mui/material";
import { colors } from "../styles/styles";

export const Input = styled("input")(({ width = "auto" }) => ({
	padding: "15px 25px",
	borderRadius: 25,
	background: colors.grayMain,
	textTransform: "none",
	width: width,
	fontSize: 16,
	border: "none",
	color: colors.blackMain,
	"&:focus": {
		outline: "none"
	}
}));
