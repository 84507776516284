export const colors = {
	redMain: "#BB3D3D",
	redSecondary: "#973434",
	whiteMain: "#FFFFFF",
	blackMain: "rgba(0, 0, 0, 0.75)",
	black50: "rgba(0, 0, 0, 0.5)",
	grayMain: "#F1F1F1"
};

export const styles = {
	page: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100vw",
		height: "100vh",
		overflowX: "hidden"
	},
	innerPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: "1.5rem 3.5rem"
	},
	mobileInnerPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: "1.25rem"
	},
	banner: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100vw",
		padding: "2rem 3.5rem",
		backgroundColor: colors.redMain,
		position: "fixed",
		top: 0,
		zIndex: 2
	},
	mobileBanner: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100vw",
		padding: "1.125rem",
		backgroundColor: colors.redMain,
		position: "fixed",
		top: 0,
		zIndex: 2
	},
	titleTypography: {
		color: colors.blackMain,
		fontSize: "3.5rem",
		fontWeight: 700
	},
	smallTitleTypography: {
		color: colors.blackMain,
		fontSize: "1.75rem",
		fontWeight: 700,
		textAlign: "center"
	},
	mobileSmallTitleTypography: {
		color: colors.blackMain,
		fontSize: "1rem",
		fontWeight: 600,
		textAlign: "center"
	},
	whiteSmallTitleTypography: {
		color: colors.whiteMain,
		fontSize: "1.75rem",
		fontWeight: 700
	},
	smallRegularTypography: {
		color: colors.blackMain,
		fontSize: "1.5rem",
		fontWeight: 400,
		textAlign: "center"
	},
	smallerRegularTypography: {
		color: colors.blackMain,
		fontSize: "1.25rem",
		fontWeight: 400,
		textAlign: "center"
	},
	smallerTitleTypography: {
		color: colors.blackMain,
		fontSize: "1.25rem",
		fontWeight: 700,
		textAlign: "center"
	},
	smallerWhiteTitleTypography: {
		color: colors.whiteMain,
		fontSize: "1.25rem",
		fontWeight: 700,
		textAlign: "center"
	},
	mobileSmallRegularTypography: {
		color: colors.blackMain,
		fontSize: "0.875rem",
		fontWeight: 400,
		textAlign: "center"
	},
	gameList: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexWrap: "wrap",
		gap: "1rem",
		width: "58.25rem"
	},
	mobileGameList: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexWrap: "wrap",
		gap: "1rem",
		width: "21.88rem"
	},
	gameListing: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "0.62rem"
	},
	mobileGameListing: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "0.75rem"
	},
	exitConfirmation: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "1rem",
		padding: "1.5rem 3.5rem",
		borderRadius: "1.5625rem",
		boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 7px 0px rgba(0, 0, 0, 0.20)",
		position: "absolute",
		top: "35%",
		zIndex: 2,
		backgroundColor: colors.whiteMain
	},
	exitConfirmationButtonGray: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "21.875rem",
		padding: "0.75rem 1.25rem",
		borderRadius: "1.5625rem",
		backgroundColor: colors.grayMain,
		textTransform: "none"
	},
	exitConfirmationButtonRed: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "21.875rem",
		padding: "0.75rem 1.25rem",
		borderRadius: "1.5625rem",
		backgroundColor: colors.redMain,
		textTransform: "none"
	}
};

export const JoinPageStyles = {
	splashPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		height: "100%",
		width: "100%",
		backgroundColor: colors.redMain
	},
	page: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		height: "100%",
		width: "100%",
		backgroundColor: colors.whiteMain,
		position: "relative"
	},
	innerPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100%",
		padding: 20,
		gap: 16,
		flex: 1,
		position: "relative"
	},
	innerCenteredPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		flex: 1,
		padding: 20,
		gap: 16
	},
	banner: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
		height: 100,
		width: "100%",
		paddingBottom: 20,
		backgroundColor: colors.redMain,
		paddingX: 20
	},
	titleText: {
		fontSize: 28,
		fontWeight: "700",
		color: colors.blackMain,
		textAlign: "center",
		margin: 0
	},
	inputBoxStyle: {
		display: "flex",
		width: "100%",
		padding: "12px 20px",
		alignItems: "center",
		justifyContent: "flex-start",
		borderRadius: 25,
		backgroundColor: colors.grayMain,
		fontSize: 16,
		fontWeight: "400",
		color: colors.blackMain,
		border: "none",
		boxSizing: "border-box"
	},
	button: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		maxWidth: 400,
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.redMain,
		border: "none"
	},
	disabledButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		maxWidth: 400,
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.redMain,
		opacity: 0.5,
		border: "none"
	},
	buttonText: {
		fontSize: 16,
		fontWeight: "700",
		color: colors.whiteMain
	},
	normalText: {
		fontSize: 16,
		fontWeight: "400",
		color: colors.blackMain,
		textAlign: "center"
	},
	smallText: {
		fontSize: 14,
		fontWeight: "400",
		color: colors.blackMain,
		textAlign: "center",
		margin: 0
	},
	normalTextBold: {
		fontSize: 16,
		fontWeight: "600",
		color: colors.blackMain,
		textAlign: "center"
	},
	errorBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100%",
		maxWidth: 400
	},
	errorText: {
		fontSize: 14,
		fontWeight: "400",
		color: colors.redMain
	},
	line: {
		width: "100%",
		maxWidth: 400,
		height: 0.5,
		backgroundColor: colors.blackMain
	},
	rowJustifyBetween: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		maxWidth: 400
	},
	settingsButton: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.grayMain,
		gap: 10,
		padding: "8px 24px",
		borderRadius: 100
	},
	settingsButtonText: {
		fontSize: 16,
		fontWeight: "700",
		color: colors.blackMain
	},
	popupBackground: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "20px 28px",
		position: "absolute",
		top: 160,
		gap: 10,
		borderRadius: 25,
		backgroundColor: colors.whiteMain,
		width: 293,
		zIndex: 2
	},
	deleteConfirmationButtonGray: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.grayMain
	},
	deleteConfirmationButtonRed: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.redMain
	},
	editConfirmationButtonGray: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1,
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.grayMain
	},
	editConfirmationButtonRed: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexGrow: 1,
		padding: "12px 20px",
		borderRadius: 25,
		backgroundColor: colors.redMain
	},
	gameControllerContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
		position: "absolute"
	},
	leaveConfirmationBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "24px 56px",
		gap: 16,
		borderRadius: 25,
		backgroundColor: colors.whiteMain,
		position: "absolute",
		zIndex: 7
	}
};

export const VisibileEye = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
			<path
				d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
				fill={"#888"}
			/>
		</svg>
	);
};

export const NotVisibleEye = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
			<path
				d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"
				fill={"#888"}
			/>
		</svg>
	);
};
