import styled from "styled-components";
import { WhiteButton } from "../../basic/inputs";
import { RowBox } from "../../boxes";
import { Box } from "@mui/material";
import { colors } from "../../../styles/styles";
import { useEffect, useState } from "react";
import { Text } from "../../text";

const UploadBackground = styled(Box)(({ dragging = "false" }) => ({
	display: "flex",
	padding: "25px 0px",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: 15,
	width: "100%",
	borderRadius: 10,
	border: "1px dashed rgba(0, 0, 0, 0.50)",
	backgroundColor: colors.grayMain,
	opacity: dragging === "true" ? 0.5 : 1,
}));

const UploadBox = ({ accept, id, mult, change, waitingForApproval }) => {
	const [dragging, setDragging] = useState(false);
	const handleClick = () => {
		document.getElementById(`fileInput${id}`).click();
	};

	useEffect(() => {
		document.getElementById(`fileInput${id}`).addEventListener("change", change);
	}, []);

	const handleDragEnter = (e) => {
		if (e.target === e.currentTarget) {
			setDragging(true);
		}
	};

	const handleDragLeave = (e) => {
		if (e.target === e.currentTarget) {
			setDragging(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setDragging(false);
		const event = { target: { files: e.dataTransfer.files } };
		change(event);
	};

	return (
		<UploadBackground
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={(e) => e.preventDefault()}
			onDrop={handleDrop}
			dragging={dragging.toString()}
		>
			<input type="file" id={`fileInput${id}`} style={{ display: "none" }} accept={accept} multiple={mult} disabled={waitingForApproval} />
			<WhiteButton
				disableRipple={true}
				onClick={handleClick}
				disabled={waitingForApproval}
				style={{ pointerEvents: dragging ? "none" : "auto" }}
			>
				<RowBox gap={10}>
					<svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
						<path
							d="M5.53095 4.24613L3.84459 5.9325C3.4311 6.34598 2.76071 6.34598 2.34722 5.9325C1.93374 5.51901 1.93374 4.84862 2.34722 4.43513L5.84107 0.941279C6.25456 0.527793 6.92495 0.527793 7.33844 0.941279L10.8323 4.43513C11.2458 4.84862 11.2458 5.51901 10.8323 5.9325C10.4188 6.34598 9.74841 6.34598 9.33493 5.9325L7.64855 4.24611V10.7806C7.64855 11.3653 7.17451 11.8394 6.58975 11.8394C6.00499 11.8394 5.53095 11.3653 5.53095 10.7806V4.24613Z"
							fill="black"
							fillOpacity="0.5"
						/>
						<rect
							x="0.624878"
							y="15.3688"
							width="7.76451"
							height="2.11759"
							transform="rotate(-90 0.624878 15.3688)"
							fill="black"
							fillOpacity="0.5"
						/>
						<rect
							x="0.624878"
							y="15.3688"
							width="2.11759"
							height="10.588"
							transform="rotate(-90 0.624878 15.3688)"
							fill="black"
							fillOpacity="0.5"
						/>
						<rect
							x="10.5076"
							y="15.3688"
							width="7.76451"
							height="2.11759"
							transform="rotate(-90 10.5076 15.3688)"
							fill="black"
							fillOpacity="0.5"
						/>
					</svg>
					<Text size={14} weight={700} color={colors.black50}>
						Upload
					</Text>
				</RowBox>
			</WhiteButton>
			<Text size={12} color={colors.black50} style={{ pointerEvents: "none" }}>
				or drag and drop
			</Text>
		</UploadBackground>
	);
};

export default UploadBox;
