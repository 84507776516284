const { useState } = require("react");

export const defaultUserData = {};

const useUserData = () => {
	const [user, setUser] = useState({});

	const updateUserData = (user) => {
		setUser(user);
	};

	return { user, updateUserData };
};

export default useUserData;
