import React, { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/global-context";

interface AppLockProps {
	children: React.ReactNode | React.ReactNode[];
}

//this locks down the app until current the user loads, but mostly should be used for when the application is loading, if refreshed this should lock down and when getting global variables this should remain online until details are found and downloaded
const AppLock: React.FC<AppLockProps> = ({ children }) => {
	const { auth } = useContext(GlobalContext);
	const [loaded, setLoaded] = React.useState(false);

	const unlockApp = useCallback(() => {
		setLoaded(true);
		const loadingPortal = document.getElementById("loading-portal");
		if (loadingPortal) {
			const child = loadingPortal.children?.[0];
			if (child) {
				child.classList.add("fade-out");
				setTimeout(() => {
					loadingPortal.innerHTML = "";
				}, 500);
			}
		}
	}, [setLoaded]);

	useEffect(() => {
		if (!auth.loading && !loaded) {
			unlockApp();
		}
	}, [auth]);

	return <div>{children}</div>;
};

export default AppLock;
