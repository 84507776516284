import { useEffect, useRef, useState } from "react";

const useAuthToken = () => {
	const [token, setToken] = useState(null);
	const [expiresAt, setExpiresAt] = useState(null);
	const [refreshToken, setRefreshToken] = useState(null);
	const tokenTimeout = useRef();

	//store the auth token for the client, that way we can utilize it later when we need to authenticate the user on the backend
	const updateToken = (token, expiresAt, refreshToken) => {
		setToken(token);
		if (expiresAt) setExpiresAt(expiresAt);
		if (refreshToken) setRefreshToken(refreshToken);
	};

	const logout = () => {
		setToken(null);
		setExpiresAt(null);
		setRefreshToken(null);
	};

	//token refresh
	useEffect(() => {
		if (!expiresAt || !refreshToken) return;
		const timeToRefresh = expiresAt - Date.now();
		if (timeToRefresh <= 0) {
			// TODO: refresh the token
		} else {
			tokenTimeout.current = setTimeout(() => {
				// TODO: refresh the token
			}, timeToRefresh);
		}
		//clear the token refresh timer
		return () => {
			if (tokenTimeout && tokenTimeout.current) {
				clearTimeout(tokenTimeout.current);
			}
		};
	}, [expiresAt, refreshToken]);

	return { updateToken, token, logout };
};

export default useAuthToken;
