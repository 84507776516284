import { useCallback, useMemo, useState } from "react";

export const LBHookStatusNum = ["NOT_LOADED", "LOADING", "SUCCESS", "ERROR", "NO_DATA"];

export const LBHookStatusName = {
	NOT_LOADED: 0,
	LOADING: 1,
	SUCCESS: 2,
	ERROR: 3,
	NO_DATA: 4,
};

export const defaultLoadingBasics = {
	current: 0,
	name: "NOT_LOADED",
	errorMsg: null,
	loading: () => {},
	reset: () => {},
	success: () => {},
	noData: () => {},
	get: () => "NOT_LOADED",
	error: () => {},
	clearError: () => {},
};

/**
 * A Hook that is used for basic loading states for api calls or for other async functions
 * @param {*} defaultVal base value for the hook to start with if you want
 * @returns {
 * current: string,
 * errorMsg: string,
 * loading: function,
 * reset: function,
 * success: function,
 * get: function,
 * error: function
 * }
 */
const useLoadingBasics = (defaultVal = "NOT_LOADED") => {
	const [current, setCurrent] = useState(LBHookStatusName?.[defaultVal] ?? LBHookStatusName?.["NOT_LOADED"]);
	const [errorMsg, setErrorMsg] = useState(null);

	const reset = useCallback(() => {
		setCurrent(LBHookStatusName["NOT_LOADED"]);
		setErrorMsg(null);
	}, [setCurrent, setErrorMsg]);

	const loading = useCallback(() => {
		setCurrent(LBHookStatusName["LOADING"]);
		setErrorMsg(null);
	}, [setCurrent, setErrorMsg]);

	const success = useCallback(() => {
		setCurrent(LBHookStatusName["SUCCESS"]);
		setErrorMsg(null);
	}, [setCurrent, setErrorMsg]);

	const noData = useCallback(() => {
		setCurrent(LBHookStatusName["NO_DATA"]);
		setErrorMsg(null);
	}, [setCurrent, setErrorMsg]);

	const clearError = useCallback(() => {
		setErrorMsg(null);
	}, [setErrorMsg]);

	const error = useCallback(
		(msg) => {
			setCurrent(LBHookStatusName["ERROR"]);
			setErrorMsg(msg);
		},
		[setErrorMsg, setCurrent]
	);

	const name = useMemo(() => {
		return LBHookStatusNum[current];
	}, [current]);

	return {
		current,
		name,
		errorMsg,
		loading,
		reset,
		success,
		noData,
		error,
		clearError,
	};
};

export default useLoadingBasics;
