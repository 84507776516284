import { useCallback, useMemo, useRef, useState } from "react";

interface UseDragDropList {
	cbs: (i: number) => DragDropCbs;
	containerCbs: ContainerCbs;
	dragging: number | null;
	dragOver: number | null;
}

interface DragDropCbs {
	onDrag: () => void;
	onDragOver: (e: React.DragEvent<HTMLElement>) => void;
	onDragLeave: () => void;
	onDrop: () => void;
}

interface ContainerCbs {
	onDragLeave: () => void;
	onDragOver: () => void;
}

interface UseDragDropListProps {
	onChange: (dragIndex: number, droppedIndex: number) => void;
	useDragNDrop?: boolean;
}

const useDragDropList: (props: UseDragDropListProps) => UseDragDropList = ({ onChange, useDragNDrop = true }) => {
	const [lastDragged, setLastDragged] = useState<number | null>(null);
	const [dragging, setDragging] = useState<null | number>(null);
	const [dragOver, setDragOver] = useState<null | number>(null);
	const timeout = useRef<NodeJS.Timeout>();

	const onDrag = useCallback(
		(indx: number) => {
			if (indx !== dragging) {
				setDragging(indx);
				setLastDragged(indx);
			}
		},
		[setDragging, setLastDragged, dragging]
	);

	const onDragOver = useCallback(
		(e: React.DragEvent<HTMLElement>, indx: number) => {
			//make sure to prevent default or else the drop method won't really work
			e.preventDefault();
			clearTimeout(timeout.current as NodeJS.Timeout);
			if (dragOver !== indx) {
				setDragOver(indx);
			}
		},
		[dragOver, setDragOver]
	);

	const onDragLeave = useCallback(() => {
		//when the user leaves an area just assign null
		clearTimeout(timeout.current as NodeJS.Timeout);
		setDragOver(null);
	}, [setDragOver]);

	const onDrop = useCallback(
		async (i: number) => {
			if (useDragNDrop && dragging !== null && dragOver !== null) {
				await onChange(dragging, dragOver);
			}
			setLastDragged(dragging);
			setDragging(null);
			setDragOver(null);
		},
		[useDragNDrop, onChange, dragging, dragOver, setLastDragged, setDragging, setDragOver]
	);

	const onContainerLeave = useCallback(() => {
		clearTimeout(timeout.current as NodeJS.Timeout);
		const tcb = () => {
			setLastDragged(dragging);
			setDragging(null);
			setDragOver(null);
		};
		timeout.current = setTimeout(tcb, 500);
	}, [dragging, setLastDragged, setDragging, setDragOver]);

	const onContainerEnter = useCallback(() => {
		clearTimeout(timeout.current as NodeJS.Timeout);
		if (dragging !== null) return;
		setDragging(lastDragged);
	}, [setDragging, lastDragged, dragging]);

	const cbs = useCallback(
		(indx: number) => {
			return {
				onDrag: () => onDrag(indx),
				onDragOver: (e: React.DragEvent<HTMLElement>) => onDragOver(e, indx),
				onDragLeave: onDragLeave,
				onDrop: () => onDrop(indx),
			};
		},
		[onDrag, onDragOver, onDragLeave, onDrop]
	);

	const containerCbs = useMemo(() => {
		return { onDragLeave: onContainerLeave, onDragOver: onContainerEnter };
	}, [onContainerLeave, onContainerEnter]);

	return { cbs, containerCbs, dragging, dragOver };
};

export default useDragDropList;
