import { Box, styled } from "@mui/material";
import { colors } from "../styles/styles";

export const PageBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100vw",
	minHeight: "100vh",
	overflowX: "hidden",
	backgroundColor: colors.whiteMain
});

export const ColumnBox = styled(Box)(({ gap = 0, align = "center", width = "auto" }) => ({
	display: "flex",
	alignItems: align,
	flexDirection: "column",
	justifyContent: "center",
	gap: gap,
	width: width
}));

export const RowBox = styled(Box)(({ gap = 0, width = "auto", background = "transparent", align = "center", justify = "center" }) => ({
	display: "flex",
	alignItems: align,
	flexDirection: "row",
	justifyContent: justify,
	gap: gap,
	width: width,
	backgroundColor: background
}));

export const ConstraintBox = styled(Box)(({ width = "100%", height = "100%" }) => ({
	width: width,
	height: height
}));

export const HorizontalLine = styled(Box)(({ width = "100vw", color = colors.blackMain }) => ({
	width: width,
	height: 0.5,
	backgroundColor: color
}));

export const ErrorBox = styled(Box)(({ width = "auto" }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	width: width
}));

export const ScrollableBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	gap: 15,
	overflowY: "auto",
	height: "100%",
	width: "100%",
	alignItems: "center",
	justifyContent: "flex-start"
});

export const StatBar = styled(RowBox)(({ selected }) => ({
	justifyContent: "space-between",
	width: "calc(100% - 50px)",
	backgroundColor: selected ? colors.grayMain : colors.whiteMain,
	borderRadius: 100,
	padding: "10px 25px"
}));
