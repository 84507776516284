import { useCallback, useContext, useEffect, useState } from "react";
import useLoadingBasics, { defaultLoadingBasics } from "../use-loading-basics";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";
import { supabase } from "../../lib/supabase";

export const defaultGamePoliciesHook = { price: 0, discount: 0, status: defaultLoadingBasics };

const useGamePolicies = () => {
	const { auth } = useContext(GlobalContext);
	const { gameId } = useParams();
	const [price, setPrice] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [cut, setCut] = useState(0);
	const [originalVals, setOriginalVals] = useState({ price: 0, discount: 0 });
	const [modified, setModified] = useState(false);
	const [error, setError] = useState(false);
	const status = useLoadingBasics();

	const updatePrice = useCallback(
		(price) => {
			setPrice(price);
		},
		[setPrice]
	);

	//give a callback for the discount to be updated at
	const updateDiscount = (discount) => {
		setDiscount(discount);
	};

	//get the game polices which include pricing and discount
	const getGamePricing = async () => {
		status.loading();
		const developerId = auth.user.id;
		const dev_game_poilices = await supabase.from("dev_game_policies").select("*").eq("game_id", gameId).eq("developer_id", developerId);
		const admin_policies = await supabase.from("admin_game_policies").select("*").eq("game_id", gameId).eq("developer_id", developerId);
		if (!dev_game_poilices?.error && dev_game_poilices?.data?.length > 0 && !admin_policies?.error && admin_policies?.data?.length > 0) {
			const policies = dev_game_poilices.data[0];
			const aPolicies = admin_policies.data[0];
			setOriginalVals({ price: policies.price, discount: policies.discount });
			setModified(false);
			setPrice(policies.price);
			setDiscount(policies.discount);
			setCut(aPolicies.dev_cut_perc);
			status.success();
		} else {
			if (dev_game_poilices?.data?.length === 0 && admin_policies?.data?.length === 0)
				status.error("No dev and admin policies found for this game");
			else if (dev_game_poilices?.data?.length === 0) status.error("No dev policies found for this game");
			else if (admin_policies?.data?.length === 0) status.error("No admin policies found for this game");
			else if (dev_game_poilices?.error && admin_policies?.error) status.error(dev_game_poilices.error + " and " + admin_policies.error);
			else if (dev_game_poilices?.error) status.error(dev_game_poilices.error);
			else if (admin_policies?.error) status.error(dev_game_poilices.error);
			status.error();
		}
	};

	//update the game pricing
	const updateGamePricing = async () => {
		status.loading();
		const result = await supabase
			.from("dev_game_policies")
			.update({ discount, price })
			.eq("game_id", gameId)
			.eq("developer_id", auth.user.id);
		if (result.error) {
			status.error();
			return false;
		}
		status.success();
		setModified(false);
		setOriginalVals({ price, discount });
		return true;
	};

	//when ever the price or discount is changed then check if the values are modified from the original
	useEffect(() => {
		if (price !== originalVals.price || discount !== originalVals.discount) setModified(true);
		else setModified(false);
	}, [price, discount]);

	//when the client has obtained all the necessary information then get the game pricing
	useEffect(() => {
		if (gameId && auth.user && auth.signedIn) getGamePricing();
	}, [supabase, gameId, auth.user]);

	return { price, discount, modified, cut, updatePrice, updateDiscount, updateGamePricing, status, error, setError };
};

export default useGamePolicies;
