import { supabase } from "../lib/supabase";

export async function handleGetDeveloperGames() {
	try {
		const { data: userData, error: userError } = await supabase.auth.getUser();
		if (userError) {
			console.log(userError);
			return null;
		}
		const developerId = userData.user.id;
		const { data, error } = await supabase
			.from("developer_games")
			.select("*")
			.eq("developer_id", developerId)
			.order("last_updated", { ascending: false });
		if (error) {
			console.log(error);
			return null;
		}
		return data;
	} catch (err) {
		console.log(err);
	}
}

export async function handleChangeGameType(gameId, gameType) {
	try {
		const { data, error } = await supabase.from("developer_games").update({ type: gameType }).eq("game_id", gameId);
		if (error) {
			console.log(error);
			return null;
		}
		console.log(data);
		return data;
	} catch (err) {
		console.log(err);
	}
}

export async function handleAddDeveloperGameDefault() {
	try {
		const { data, error } = await supabase.rpc("insert_developer_game");
		if (error) {
			console.log(error);
			return null;
		}
		return data;
	} catch (err) {
		console.log(err);
	}
}

export async function handleGetDeveloperGame(gameId) {
	try {
		const { data: userData, error: userError } = await supabase.auth.getUser();
		if (userError) {
			console.log(userError);
			return null;
		}
		const developerId = userData.user.id;
		const { data, error } = await supabase
			.from("developer_games")
			.select("*")
			.eq("game_id", gameId)
			.eq("developer_id", developerId)
			.order("version", { ascending: true });
		if (error) {
			console.log(error);
			return null;
		}
		return data;
	} catch (err) {
		console.log(err);
	}
}

export async function handleUpdateDeveloperGame(gameId, version, data) {
	try {
		const { data: userData, error: userError } = await supabase.auth.getUser();
		if (userError) {
			console.log(userError);
			return null;
		}
		const developerId = userData.user.id;
		// get timestamptz and append it to the data as last_updated
		data.last_updated = new Date().toISOString();
		const { error } = await supabase
			.from("developer_games")
			.update(data)
			.eq("game_id", gameId)
			.eq("developer_id", developerId)
			.eq("version", version);
		if (error) {
			console.log(error);
			return null;
		}
		return true;
	} catch (err) {
		console.log(err);
	}
}

export async function handleGetControllerUrl(controller) {
	try {
		const { data, error } = await supabase.from("madder_controllers").select("url").eq("name", controller);
		if (error) {
			console.log(error);
			return null;
		}
		return data[0]?.url;
	} catch (err) {
		console.log(err);
	}
}

export async function handleAddNewGameVersion(gameId) {
	try {
		const { data, error } = await supabase.rpc("new_developer_game_version", { _game_id: gameId });
		if (error) {
			console.log(error);
			return null;
		}
		return data;
	} catch (err) {
		console.log(err);
	}
}
