import { useContext, useMemo, useState } from "react";
import { GameManagementContext } from "../../context/game-management-context";
import { colors } from "../../styles/styles";
import { InfoBox } from "../basic/containers";
import { ColumnBox, RowBox } from "../boxes";
import { Text } from "../text";
import UploadBox from "../input/upload/upload-box";
import styled from "styled-components";
import BuildFileHolder from "../basic/build-file-container";
import useDragDropList from "../../hooks/use-drag-drop-list";
import { TrashIcon } from "../icons";
import { Box, Modal } from "@mui/material";
import { RedButton, WhiteButton } from "../basic/inputs";

const StyledImg = styled.img`
	width: 100%;
	height: auto;
	object-fit: contain;
`;

const StyledMediaContainer = styled(({ selectedDrag, ...rest }) => <div {...rest} />)`
	min-width: 200px;
	min-height: 200px;
	max-width: 200px;
	max-height: 200px;
	padding-right: 10px;
	transition: 0.2s ease-in-out padding-left;
	padding-left: ${(props) => (props.selectedDrag ? "50px" : "0px")};
	position: relative;
	cursor: move;
`;

const StyledVideo = styled.video`
	width: 100%;
	height: auto;
	object-fit: contain;
`;

const StyledEndingDroppable = styled(({ selectedDrag, ...rest }) => <div {...rest} />)`
	max-height: 200px;
	min-width: 50px;
	width: 100%;
	height: 100%;
	transition: 0.2s ease-in-out padding-left;
	padding-left: ${(props) => (props.selectedDrag ? "50px" : "0px")};
`;

const StyledTrashContainer = styled.div`
	position: absolute;
	top: 20px;
	right: 0px;
	cursor: pointer;
	z-index: 10;
	transform: translate(-50%, -50%);
`;

const StyledTrash = styled(TrashIcon)``;

const MediaContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	overflow-x: scroll;
	border: 1px solid ${colors.black50};
	border-radius: 5px;
	width: calc(100% - 20px);
	height: calc(200px + 20px);
`;

const StyledModalBox = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: ${colors.whiteMain};
	border-radius: 5px;
	box-shadow: 24px;
	padding: 16px;
`;

const MediaSection = () => {
	const {
		media,
		waitingForApproval,
		gameVersions: { v },
	} = useContext(GameManagementContext);

	const [trashItem, setTrashItem] = useState(-1);
	const [deleteError, setDeleteError] = useState("");

	const onUpload = (e) => {
		if (e.target.files) {
			media.addFilesToUpload(e.target.files);
		}
	};

	const handleMediaDeletion = async () => {
		const res = await media.deleteMediaUrl(trashItem);
		if (res) {
			setTrashItem(-1);
		} else {
			setDeleteError("Failed to remove media");
		}
	};

	const isModifiable = useMemo(() => {
		return v.status !== "Approved" && v.status !== "Waiting for approval";
	}, [v]);

	const handleDropChange = async (ci, ni) => {
		if (ci === ni - 1 || ci === ni) return;
		//new priority
		let np = 0;
		if (ni === 0) {
			np = media.mediaUrls[0].priority - 1;
		} else if (ni === media.mediaUrls.length) {
			np = media.lastPriority + 1;
		} else {
			np = (media.mediaUrls[ni - 1].priority + media.mediaUrls[ni].priority) / 2;
		}
		await media.changePriority(ci, np);
	};

	const dgdp = useDragDropList({ list: media.mediaUrls, onChange: handleDropChange });
	return (
		<>
			<Modal onClose={() => setTrashItem(-1)} open={trashItem >= 0}>
				<StyledModalBox>
					<ColumnBox align={"center"} gap={10} width={"calc(100% - 40px)"} style={{ padding: "20px" }}>
						<Text color={colors.black50} weight={700}>
							Are you sure you want to delete this media?
						</Text>
						<RowBox gap={10}>
							<RedButton onClick={handleMediaDeletion}>
								<Text style={{ color: colors.whiteMain }}>Yes</Text>
							</RedButton>
							<WhiteButton
								style={{
									border: "1px solid var(--black-50, rgba(0, 0, 0, 0.50))",
								}}
								onClick={() => setTrashItem(-1)}
							>
								<Text colors={colors.blackMain}>No</Text>
							</WhiteButton>
						</RowBox>
						{deleteError && <Text color={colors.redMain}>{deleteError}</Text>}
					</ColumnBox>
				</StyledModalBox>
			</Modal>
			<InfoBox>
				<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
					<Text weight={700}>Media</Text>
					<Text size={12} color={colors.black50}>
						Add images and videos for the Madder Store
					</Text>
				</ColumnBox>
				{v.status !== "Approved" && (
					<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
						<RowBox width={"100%"} gap={25}>
							<UploadBox id={3} accept={".jpg,.jpeg,.png"} change={onUpload} mult={true} waitingForApproval={waitingForApproval} />
						</RowBox>
					</ColumnBox>
				)}
				{media.filesToUpload.length > 0 && (
					<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
						<RowBox width={"100%"} gap={5} style={{ overflowX: "scroll" }} justify={"flex-start"}>
							{media.filesToUpload.map((file, index) => {
								return <BuildFileHolder key={`file-upload-${file.name}-${index}`} url={file.name} file={null} fileExtension={file.type} />;
							})}
						</RowBox>
					</ColumnBox>
				)}
				<ColumnBox align={"flex-start"} gap={10} width={"100%"} {...dgdp.containerCbs}>
					<MediaContainer>
						{media.mediaUrls.map((item, index) => {
							if (dgdp.dragging !== index && item.url) {
								const isImage = item.url.match(/\.(jpeg|jpg|gif|png)$/) != null;
								const isVideo = item.url.match(/\.(mp4|mov)$/) != null;
								//check if the extension is an image or not
								return (
									<StyledMediaContainer
										selectedDrag={index === dgdp.dragOver}
										draggable={isModifiable}
										{...(isModifiable ? dgdp.cbs(index) : {})}
										key={`media-item-${item.id}-${index}`}
									>
										{isImage && <StyledImg src={item.url} />}
										{isVideo && <StyledVideo src={item.url} />}
										{isModifiable && (
											<StyledTrashContainer
												onClick={() => {
													setTrashItem(index);
												}}
											>
												<StyledTrash />
											</StyledTrashContainer>
										)}
									</StyledMediaContainer>
								);
							}
						})}
						{dgdp.dragging !== null && (
							<StyledEndingDroppable {...dgdp.cbs(media.mediaUrls.length)} selectedDrag={dgdp.dragOver === media.mediaUrls.length} />
						)}
					</MediaContainer>
				</ColumnBox>
			</InfoBox>
		</>
	);
};

export default MediaSection;
