import { useEffect, useMemo, useState } from "react";
import { supabase } from "../../lib/supabase";
import { saveToS3 } from "../../resources/s3.resource";
import _ from "lodash";

interface GameMediaProps {
	gameId: string;
	version: number;
}

export interface GameMediaHook {
	mediaUrls: MediaItem[];
	filesToUpload: File[];
	lastPriority: number;
	addFilesToUpload: (files: File[]) => void;
	removeFileAtIndex: (index: number) => void;
	removeFileWithName: (name: string) => void;
	addFileToMedia: (nMedia: MediaItem) => void;
	deleteMediaUrl: (index: number) => Promise<boolean>;
	uploadFiles: (body: { gameId: string; version: number }) => Promise<void>;
	addNewVersionMedia: (newVersion: number) => Promise<boolean>;
	changePriority: (mediaUrlIndex: number, newPriority: number) => void;
}

export const defaultGameMediaHook: GameMediaHook = {
	mediaUrls: [],
	filesToUpload: [],
	lastPriority: 0,
	addFilesToUpload: () => {},
	removeFileAtIndex: () => {},
	removeFileWithName: () => {},
	addFileToMedia: () => {},
	deleteMediaUrl: async () => false,
	uploadFiles: async () => {},
	addNewVersionMedia: async () => false,
	changePriority: () => {},
};

interface BaseMediaItem {
	url: string;
	priority: number;
	version: number;
}

interface MediaItem extends BaseMediaItem {
	id: number;
}

interface MediaUploadItem extends MediaItem {
	game_id: string;
}
//SSStr stands for supabase select string
const mediaItemSSStr = "url,id,priority, version";

const useGameMedia: (props: GameMediaProps) => GameMediaHook = ({ gameId, version }) => {
	//state comprising of the media urls whether it's video or not
	const [mediaUrls, setMediaUrls] = useState<MediaItem[]>([]);
	const [allMedia, setAllMedia] = useState<MediaItem[]>([]);
	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

	const getMedia = async () => {
		try {
			const media = await supabase.from("games_media").select(mediaItemSSStr).eq("game_id", gameId).order("priority", { ascending: true });
			if (media.status === 200) {
				setAllMedia(media.data as MediaItem[]);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const lastPriority = useMemo(() => {
		let l = 0;
		for (let i = 0; i < mediaUrls.length; i++) {
			if (mediaUrls[i].priority > l) {
				l = mediaUrls[i].priority;
			}
		}
		return l;
	}, [mediaUrls]);

	useEffect(() => {
		getMedia();
	}, []);

	useEffect(() => {
		setMediaUrls(allMedia.filter((m) => m.version === version));
	}, [version, allMedia]);

	const addFilesToUpload = (files: File[]) => {
		setFilesToUpload((filesTU) => _.cloneDeep([...filesTU, ...files]));
	};

	const removeFileAtIndex = (index: number) => {
		const newFiles = [...filesToUpload];
		newFiles.splice(index, 1);
		setFilesToUpload(newFiles);
	};

	const addFileToMedia = (nMedia: MediaItem) => {
		//make sure to add it to all media that way it properly updates when switching versions
		setAllMedia((media) => [...media, nMedia]);
	};

	const removeFileWithName = (name: string) => {
		setFilesToUpload((files: File[]) => files.filter((file) => file.name !== name));
	};

	const deleteMediaUrl = async (index: number) => {
		try {
			const mediaId = mediaUrls[index].id;
			const response = await supabase.from("games_media").delete().eq("id", mediaId);
			if (!response?.error) {
				setAllMedia((media) => _.cloneDeep(media).filter((m) => m.id !== mediaId));
				return true;
			}
			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	const uploadFiles = async () => {
		for (let i = 0; i < filesToUpload.length; i++) {
			const file = filesToUpload[i];
			const cb = async (url) => {
				//upload the file to the database and create the priority as a number bigger than the last one, this helps us to order the media a little bit better and not run out of the 8 bit floating points
				const response = await supabase
					.from("games_media")
					.insert({ url: url, game_id: gameId, priority: lastPriority + 1, version: version })
					.select();
				//if we successfully create the media in the database and on the s3 bucket then add it to our media state
				if (response.status === 201 && response?.data?.[0]) {
					removeFileWithName(file.name);
					const item = response.data[0];
					addFileToMedia({ url: item.url, id: item.id, priority: item.priority, version: item.version });
				}
			};
			try {
				await saveToS3(file, () => null, cb, false, { gameId, version });
			} catch (e) {
				console.error(e);
			}
		}
		return;
	};

	const addNewVersionMedia = async (newVersion: number) => {
		//when we create a new version we need to copy the media from the previous version to the new version
		const newMedia = (_.cloneDeep(allMedia).filter((m) => m.version === newVersion - 1) as MediaUploadItem[]).map((m) => {
			m.version = newVersion;
			m.game_id = gameId;
			//@ts-ignore
			if (m?.id) delete m.id;
			return m;
		});
		try {
			const response = await supabase.from("games_media").insert(newMedia).select(mediaItemSSStr);
			if (response && response?.data?.length && response?.data?.length > 0) {
				setAllMedia((media) => _.cloneDeep([...media, ...response.data]));
				//succcess created data
				return true;
			}
			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	const changePriority = async (mediaUrlIndex: number, newPriority: number) => {
		const mediaId = mediaUrls[mediaUrlIndex].id;
		try {
			const response = await supabase.from("games_media").update({ priority: newPriority }).eq("id", mediaId).select();
			if (response.status !== 200 && !response?.data?.[0]?.priority) return console.error("Failed to update priority");
			const newMedia = allMedia
				.map((m) => {
					if (m.id === mediaId) {
						m.priority = newPriority;
					}
					return m;
				})
				.sort((a, b) => a.priority - b.priority);
			setAllMedia(newMedia);
		} catch (e) {
			console.error(e);
		}
	};

	return {
		mediaUrls,
		filesToUpload,
		lastPriority,
		addFilesToUpload,
		removeFileAtIndex,
		removeFileWithName,
		addFileToMedia,
		deleteMediaUrl,
		uploadFiles,
		addNewVersionMedia,
		changePriority,
	};
};

export default useGameMedia;
