import { supabase } from "../lib/supabase";

export const handleAppleOAuthCallback = async (id_token) => {
	try {
		const { data, error } = await supabase.auth.signInWithIdToken({
			provider: "apple",
			token: id_token
		});
		if (error) {
			console.log({ error });
			return { success: false };
		}
		if (!data?.user) return;
		const { user } = data;
		return user;
	} catch (e) {
		console.error(e);
		return null;
	}
};
