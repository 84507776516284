import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppLock from "./components/layout/app-lock";
import Router from "./routes/routes";

function App() {
	return (
		<div className="App">
			<AppLock>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</AppLock>
		</div>
	);
}

export default App;
