import { InputAdornment } from "@mui/material";
import { Text } from "../text";
import { StandardInput, WhiteButton } from "../basic/inputs";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/global-context";
import { InfoBox } from "../basic/containers";
import { colors } from "../../styles/styles";
import { ColumnBox, RowBox } from "../boxes";
import { formatPercent } from "../../utils/formatting/format-num";
import DollarInput from "../basic/dollar-input";
import { CentsToDollar } from "../../utils/formatting/format-dollar";
import { GameManagementContext } from "../../context/game-management-context";
import { openStripeLink } from "../../resources/stripe.resource";

const minimumPrice = 99; //this is $1.00 in cents

export const PricingSection = () => {
	const { auth, stripe } = useContext(GlobalContext);
	const {
		policies,
		waitingForApproval,
		gameVersions: { v },
	} = useContext(GameManagementContext);
	const [errorMsg, setErrorMsg] = useState("");

	const totalInCents = useMemo(() => {
		return parseInt(policies.price * ((100 - policies.discount) / 100));
	}, [policies.price, policies.discount]);

	useEffect(() => {
		if (totalInCents < minimumPrice && totalInCents !== 0) {
			setErrorMsg(`Price must be at least $${CentsToDollar(minimumPrice)}`);
			policies.setError(true);
		} else {
			setErrorMsg("");
			policies.setError(false);
		}
	}, [totalInCents, policies]);

	if (policies.status.name !== "SUCCESS") {
		return null;
	}
	return (
		<InfoBox>
			<Text weight={700}>Pricing</Text>
			{stripe.isConnected && (
				<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
					<RowBox width={"100%"} gap={25}>
						<ColumnBox align={"flex-start"} gap={10} width={"100%"} style={{ flex: 2 }}>
							<Text size={12}>Base Price</Text>
							{v.status !== "Approved" && (
								<DollarInput
									value={policies.price}
									onChange={(val) => {
										policies.updatePrice(val);
									}}
									disabled={waitingForApproval || !stripe.isConnected}
								></DollarInput>
							)}
							{v.status === "Approved" && <Text size={20}>${CentsToDollar(policies.price)}</Text>}
						</ColumnBox>
						<ColumnBox align={"flex-start"} gap={10} style={{ flex: 1 }}>
							<Text size={12}>Discount</Text>
							{v.status !== "Approved" && (
								<StandardInput
									placeholder={"0.00"}
									value={policies.discount}
									endAdornment={
										<InputAdornment position="end" size={12}>
											%
										</InputAdornment>
									}
									position={"end"}
									onChange={(e) => {
										e.preventDefault();
										const perc = formatPercent(e.target.value, policies.discount, 100);
										policies.updateDiscount(perc);
									}}
									disableUnderline={true}
									style={{ paddingTop: 5, paddingBottom: 5 }}
									disabled={waitingForApproval || !stripe.isConnected}
								/>
							)}
							{v.status === "Approved" && <Text size={20}>{policies.discount}%</Text>}
						</ColumnBox>
					</RowBox>
					{errorMsg && (
						<Text size={12} color={colors.redMain}>
							{errorMsg}
						</Text>
					)}
				</ColumnBox>
			)}
			<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
				{/* stripes fee is contained within the 30% they just take their cut from our cut just verified this */}
				<Text size={12}>Madder Games receives {100 - policies.cut + "%"} of each purchase made on the Madder Store.</Text>
			</ColumnBox>
			<ColumnBox align={"flex-start"} gap={10} width={"100%"}>
				<WhiteButton
					width={"100%"}
					disableRipple={true}
					style={{
						border: "1px solid var(--black-50, rgba(0, 0, 0, 0.50))",
					}}
					onClick={async () => {
						openStripeLink(auth.supaToken);
					}}
				>
					<Text size={14} weight={700} color={colors.black50}>
						{stripe.isConnected ? "Update Payment Preferences" : "Set Up Payment"}
					</Text>
				</WhiteButton>
			</ColumnBox>
		</InfoBox>
	);
};
