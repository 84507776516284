import { Box } from "@mui/material";
import { ColumnBox, PageBox } from "../boxes";
import DeveloperNavBar from "../layout/developer-nav-bar";
import { Text } from "../text";
import Lottie from "react-lottie-player";
import lottieJson from "../lotties/loading.json";
import Footer from "../footer";

const SaveOverlay = () => {
	return (
		<PageBox>
			<DeveloperNavBar />
			<ColumnBox align={"center"} justify={"center"} style={{ flex: 1 }}>
				<Text weight={700} color={"#000"}>
					Loading
				</Text>
				<Box style={{ height: 15 }} />
				<Text size={14} color={"#000"}>
					this might take a few minutes
				</Text>
				<Box style={{ height: 25 }} />
				<Lottie
					loop
					animationData={lottieJson}
					play
					style={{
						width: 50,
						height: 50,
					}}
				/>
			</ColumnBox>
			<Footer />
		</PageBox>
	);
};

export default SaveOverlay;
